import React, {cloneElement, useMemo, Fragment} from 'react';
import _ from 'lodash';
import {
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
} from 'react-admin';
import {ImportButton} from 'react-admin-import-csv';

import Typography from '@material-ui/core/Typography';

export const ListActions = (props) => {
  const {className, exporter, filters, maxResults, ...rest} = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar
      style={{
        flex: 1,
        alignItems: 'center',
        display: 'flex',
        paddingTop: 0,
        marginBotton: 0,
        borderBottom: '1px solid #eee',
      }}>
      <div
        style={{flex: 1, paddingTop: 30, paddingBottom: 20}}
        key={'filter-title'}>
        <Typography variant="h6">{props.config.label}</Typography>
      </div>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {props.config.add && <CreateButton basePath={props.basePath} />}
      {props.config.import && (
        <ImportButton
          // preCommitCallback={(action, values) => {
          //   console.log('precommit', action, values);
          // }}
          logging={true}
          // validateRow={async (row) => {
          //   if (row.id) {
          //     throw new Error('AAAA');
          //   }
          // }}
          parseConfig={{
            header: true,
            transform: (item, key) => {
              if (key === 'id') {
                return item.toString();
              }
              try {
                return JSON.parse(item);
              } catch (e) {
                return item;
              }
            },
          }}
          {...props}
        />
      )}
      {props.config.export && <ExportButton maxResults={5000} />}
    </TopToolbar>
  );
};

export default ListActions;
