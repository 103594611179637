import * as React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {useDataProvider} from 'react-admin';
import {Button, ButtonGroup} from '@material-ui/core';
// import ButtonGroup from '@material-ui/core/ButtonGroup';
import PrintIcon from '@material-ui/icons/Print';
const ButtonTransactionPrint = (props) => {
  console.log('botao', props);

  return (
    <Button
      onClick={(e) => {
        window.open(`/#/transactions/` + props.record.id + '/print', '_blank');
        e.preventDefault();
      }}>
      <PrintIcon style={{marginRight: 10}}></PrintIcon> Imprimir
    </Button>
  );
};
ButtonTransactionPrint.defaultProps = {
  addLabel: true,
};
ButtonTransactionPrint.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default ButtonTransactionPrint;
