import * as React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {useDataProvider} from 'react-admin';
import Chip from '@material-ui/core/Chip';
import BlockIcon from '@material-ui/icons/Block';
import CodeIcon from '@material-ui/icons/Code';
import AppleIcon from '@material-ui/icons/Apple';
import AndroidIcon from '@material-ui/icons/Android';
import ErrorIcon from '@material-ui/icons/Error';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const DeployVersionsField = (props) => {
  const dataProvider = useDataProvider();
  const [status, setStatus] = React.useState({
    sites: props.record.deploy?.sites?.status,
    ios: props.record.deploy?.ios?.status,
    android: props.record.deploy?.android?.status,
  });

  React.useEffect(() => {
    setStatus({
      sites: props.record.deploy?.sites?.status,
      ios: props.record.deploy?.ios?.status,
      android: props.record.deploy?.android?.status,
    });
  }, [props.record]);

  const [deploy, setDeploy] = React.useState({
    ...(props.record?.deploy ?? {}),
  });

  const [versions, setVersions] = React.useState({
    ...(props.record?.versions ?? {}),
  });

  if (versions?.android ?? false) {
    return (
      <div>
        <p>Controle para próximas builds:</p>
        <Chip
          icon={<AndroidIcon style={{color: 'black'}} />}
          label={`version: ${versions?.android?.major}.${versions?.android?.minor}.${versions?.android?.patch} build: ${versions?.android?.build}`}
        />
        <div style={{marginBottom: 10}}></div>
        <Chip
          icon={<AppleIcon style={{color: 'black'}} />}
          label={`version: ${versions?.ios?.major}.${versions?.ios?.minor}.${versions?.ios?.patch} build: ${versions?.ios?.build}`}
        />
        <br></br>
        <p>Última compilação CircleCI:</p>
        <Chip
          label={
            `version ${
              props.record.deploy?.android?.versionName ?? 'N/D'
            } build ${props.record.deploy?.android?.versionCode ?? 'N/D'}` +
            (props.record.deploy?.android?.ci?.number
              ? ' #' + props.record.deploy?.android?.ci?.number
              : '')
          }
          icon={(() => {
            switch (status.android) {
              case 'pending':
                return (
                  <>
                    <div style={{marginLeft: 5}}> </div>
                    <AutorenewIcon style={{color: 'blue'}} />
                    <AndroidIcon style={{color: 'black'}} />
                  </>
                );
              case 'error':
                return (
                  <>
                    <div style={{marginLeft: 5}}> </div>
                    <ErrorIcon style={{color: 'red'}} />
                    <AndroidIcon style={{color: 'black'}} />
                  </>
                );
              case 'complete':
                return (
                  <>
                    <div style={{marginLeft: 5}}> </div>
                    <CheckCircleIcon style={{color: 'green'}} />
                    <AndroidIcon style={{color: 'black'}} />
                  </>
                );
              default:
                return (
                  <>
                    <div style={{marginLeft: 5}}> </div>
                    <BlockIcon style={{color: 'black'}} />
                    <AndroidIcon style={{color: 'black'}} />
                  </>
                );
            }
          })()}
        />
        <div style={{marginBottom: 10}}></div>
        <Chip
          label={
            `version ${props.record.deploy?.ios?.versionName ?? 'N/D'} build ${
              props.record.deploy?.ios?.versionCode ?? 'N/D'
            }` +
            (props.record.deploy?.ios?.ci?.number
              ? ' #' + props.record.deploy?.ios?.ci?.number
              : '')
          }
          icon={(() => {
            switch (status.ios) {
              case 'pending':
                return (
                  <>
                    <div style={{marginLeft: 5}}> </div>
                    <AutorenewIcon style={{color: 'blue'}} />{' '}
                    <AppleIcon style={{color: 'black'}} />
                  </>
                );
              case 'error':
                return (
                  <>
                    <div style={{marginLeft: 5}}> </div>
                    <ErrorIcon style={{color: 'red'}} />{' '}
                    <AppleIcon style={{color: 'black'}} />
                  </>
                );
              case 'complete':
                return (
                  <>
                    <div style={{marginLeft: 5}}> </div>

                    <CheckCircleIcon style={{color: 'green'}} />
                    <AppleIcon style={{color: 'black'}} />
                  </>
                );
              default:
                return (
                  <>
                    <div style={{marginLeft: 5}}> </div>
                    <BlockIcon style={{color: 'black'}} />
                    <AppleIcon style={{color: 'black'}} />
                  </>
                );
            }
          })()}
        />
        <div style={{marginBottom: 10}}></div>
        <Chip
          label={
            `version ${
              new Date(
                props.record.deploy?.sites?.ci?.created_at,
              ).toLocaleString() ?? 'N/D'
            }` +
            (props.record.deploy?.sites?.ci?.number
              ? ' #' + props.record.deploy?.sites?.ci?.number
              : '')
          }
          icon={(() => {
            switch (status.sites) {
              case 'pending':
                return (
                  <>
                    <div style={{marginLeft: 5}}> </div>
                    <AutorenewIcon style={{color: 'blue'}} />
                    <CodeIcon style={{color: 'black'}} />
                  </>
                );
              case 'error':
                return (
                  <>
                    <div style={{marginLeft: 5}}> </div>

                    <ErrorIcon style={{color: 'red'}} />
                    <CodeIcon style={{color: 'black'}} />
                  </>
                );
              case 'complete':
                return (
                  <>
                    <div style={{marginLeft: 5}}> </div>
                    <CheckCircleIcon style={{color: 'green'}} />
                    <CodeIcon style={{color: 'black'}} />
                  </>
                );
              default:
                return (
                  <>
                    <div style={{marginLeft: 5}}> </div>
                    <BlockIcon style={{color: 'black'}} />
                    <CodeIcon style={{color: 'black'}} />
                  </>
                );
            }
          })()}
        />
      </div>
    );
  } else {
    return <div></div>;
  }
};
DeployVersionsField.defaultProps = {
  addLabel: true,
};
DeployVersionsField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default DeployVersionsField;
