import * as React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import _ from 'lodash';

const MarkdownField = ({source, record = {}}) => {
  return <ReactMarkdown>{_.get(record, source)}</ReactMarkdown>;
};
MarkdownField.defaultProps = {
  addLabel: true,
};
MarkdownField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default MarkdownField;
