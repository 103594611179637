import {useState} from 'react';
import {useListContext, TextField} from 'react-admin';
import {Button, Toolbar, Box} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

export let QueryCursor = '';

export const Pagination = () => {
  const context = useListContext();
  const {page, perPage, total, setPage} = useListContext();
  const nbPages = Math.ceil(total / perPage) || 1;
  return (
    nbPages > 1 && (
      <Toolbar style={{justifyContent: 'space-between'}}>
        {context.loading === false && (
          <>
            <Button
              style={{visibility: page > 1 ? 'visible' : 'hidden'}}
              color="primary"
              key="prev"
              onClick={() => setPage(page - 1)}>
              <ChevronLeft />
              Anterior
            </Button>
            <Button color="primary" key="total">
              {page}
            </Button>
            <Button
              style={{visibility: page !== nbPages ? 'visible' : 'hidden'}}
              color="primary"
              key="next"
              onClick={() => setPage(page + 1)}>
              Próximo
              <ChevronRight />
            </Button>
          </>
        )}
      </Toolbar>
    )
  );
};
