import React, {cloneElement, useMemo, Fragment} from 'react';
import _ from 'lodash';
import {
  useListContext,
  useNotify,
  useRedirect,
  useShowController,
  List as ListAdmin,
  Datagrid,
  DatagridCell,
  Edit as EditAdmin,
  Create as CreateAdmin,
  Tab,
  TabbedShowLayout,
  Filter,
  TopToolbar,
  BulkDeleteButton,
  SelectField,
  FormDataConsumer,
  ChipField,
  Toolbar,
  CreateButton,
  ExportButton,
  SimpleForm,
  DateField,
  CheckboxGroupInput,
  TextField,
  ImageField,
  BooleanField,
  NumberField,
  DateTimeField,
  DeleteWithConfirmButton,
  EditButton,
  TextInput,
  LongTextInput,
  DateInput,
  DateTimeInput,
  BooleanInput,
  SelectInput,
  SimpleFormIterator,
  ArrayInput,
  ArrayField,
  ImageInput,
  NumberInput,
  ShowButton,
  CloneButton,
  SaveButton,
  Show as ShowAdmin,
  SimpleShowLayout,
  ReferenceManyField,
  ReferenceField,
  downloadCSV,
  FileField,
  FileInput,
} from 'react-admin';
import MarkdownInput from 'ra-input-markdown';
import {ImportButton} from 'react-admin-import-csv';
import {Pagination} from './components/Pagination';
import {push} from 'react-router-redux';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import jsonExport from 'jsonexport/dist';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import AppBar from '@material-ui/core/AppBar';
import ToolbarUi from '@material-ui/core/Toolbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LogoImg from '../../logo.svg';
import CloseIcon from '@material-ui/icons/Close';

import {Route} from 'react-router';
import {makeStyles} from '@material-ui/core/styles';
import MarkdownField from './fields/MarkdownField';
import ProductsField from './fields/ProductsField';
import AddressField from './fields/AddressField';
import ReferenceCustomField from './fields/ReferenceField';
import ReferenceCustomInput from './inputs/ReferenceInput';
import ButtonTransactionPrint from './fields/ButtonTransactionPrint';
import TransactionsStatusField from './fields/TransactionsStatusField';
import ConsoleLinksField from './fields/ConsoleLinksField';
import DeployVersionsField from './fields/DeployVersionsField';
import BpmnField from './fields/BpmnField';
import BpmnInput from './inputs/BpmnInput';

import ListFilter from './components/ListFilter';
import ListActions from './components/ListActions';
import ViewActions from './components/ViewActions';

import {
  Checkbox,
  Table,
  TableProps,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
} from '@material-ui/core';

const useStyles = makeStyles({
  inlineBlock: {display: 'inline-flex', marginRight: '1rem'},
});

const mountArrayInputs = arrayInputs => {
  {
    return Object.keys(arrayInputs).map(fieldInside => {
      switch (arrayInputs[fieldInside].input) {
        case 'select':
          return (
            <SelectInput
              source={fieldInside}
              label={arrayInputs[fieldInside].label}
              choices={arrayInputs[fieldInside].choices}
            />
          );
        case 'options':
          return (
            <ArrayInput label={'Opções'} source={fieldInside}>
              <SimpleFormIterator>
                <TextInput source={'value'} />
              </SimpleFormIterator>
            </ArrayInput>
          );
        case 'number':
          return (
            <NumberInput
              source={fieldInside}
              label={arrayInputs[fieldInside].label}
              initialValue={0}
            />
          );
        case 'array':
          return (
            <ArrayInput
              label={arrayInputs[fieldInside].label}
              source={fieldInside}
              source={
                (arrayInputs[fieldInside].objectParentName
                  ? arrayInputs[fieldInside].objectParentName + '.'
                  : '') +
                fieldInside +
                (arrayInputs[fieldInside].objectChildName
                  ? '.' + arrayInputs[fieldInside].objectChildName
                  : '')
              }>
              <SimpleFormIterator>
                {mountArrayInputs(arrayInputs[fieldInside].fields)}
              </SimpleFormIterator>
            </ArrayInput>
          );
        default:
          //qualquer campo dentro do array vira texto, exceto os case's emcima(select, options, que dai precisam de tag customizada)
          return (
            <TextInput
              format={v => v}
              parse={v => v}
              type={
                arrayInputs[fieldInside].type
                  ? arrayInputs[fieldInside].type
                  : 'text'
              }
              source={fieldInside}
              label={arrayInputs[fieldInside].label}
            />
          );
      }
    });
  }
};

export const List = compose(connect(undefined, {push}))(props => {
  const exporter = items => {
    const itemsForExport = items.map(item => {
      let newItem = {...item};
      if (item.id) {
        newItem.id = item.id.toString();
      }

      Object.keys(item).map(key => {
        if (item[key] instanceof Object) {
          newItem[key] = JSON.stringify(item[key]);
        }
        if (typeof item[key] === 'string') {
          newItem[key] = newItem[key].replace(/\n/g, '\\n');
        }
      });
      return newItem;
    });
    jsonExport(
      itemsForExport,
      {
        typeHandlers: {
          // Number: function (value, index, parent) {
          //   return 'replaced-number';
          // },
          // String: function (value, index, parent) {
          //   return `"${value}"`;
          // },
        },
        forceTextDelimiter: true,
        textDelimiter: '"',
        headers: Object.keys(items[0]), // order fields in the export
      },
      (err, csv) => {
        downloadCSV(csv, props.resource); // download as 'items.csv` file
      },
    );
  };
  let add = {};
  if (!props.config.delete) {
    add.bulkActionButtons = false;
  } else {
    // add.bulkActionButtons = (
    //   <Fragment>
    //     {/* Add the default bulk delete action */}
    //     <BulkDeleteButton {...props} />
    //   </Fragment>
    // );
  }

  const TreeGrid = props => {
    // let niveis = 0;
    let group = props.config.treeGroups;

    const makeTree = (data, parent = null) => {
      // niveis++;
      return data.reduce(function (r, e) {
        if (e.parentId == parent) {
          e.child = makeTree(data, e.id);
          r.push(e);
        }
        return r;
      }, []);
    };

    let dataNew = _.cloneDeep(props.data);
    let dataSorted = Object.values(dataNew).sort((a, b) => {
      let groupNum =
        Object.keys(group).indexOf(a.group ? a.group : '') + '00000';
      let sum1 = groupNum * 1 + (a.order && a.order !== null ? a.order : 0);

      let groupNum2 =
        Object.keys(group).indexOf(b.group ? b.group : '') + '00000';
      let sum2 = groupNum2 * 1 + (b.order && b.order !== null ? b.order : 0);

      return sum1 - sum2;
    });

    let data2 = makeTree(dataSorted);
    const Head = props => {
      if (!props.data || props.data.length < 1) {
        return [];
      }
      return (
        <TableRow>
          {props.config.treeFields.map((field, index) => {
            return (
              <TableCell>
                <div>{props.config.fields[field].label}</div>
              </TableCell>
            );
            // switch (props.config.fields[field].input) {
            //   case 'date':
            //   default:
            //     return (
            //       <TableCell>
            //         <TextField
            //           label={props.config.fields[field].label}
            //           {...(props.config.fields[field].disabled
            //             ? {disabled: true}
            //             : null)}
            //           source={field}
            //         />
            //       </TableCell>
            //     );
            // }
          })}
          <TableCell>
            <div>Editar?</div>
          </TableCell>
        </TableRow>
      );
    };

    const Row = props => {
      if (!props.data) {
        return;
      }
      let groupControl = '';

      return props.data.map((item, linhas) => {
        let GroupRow = props => {
          if (groupControl === props.groupItem || props.count > 0) return [];

          groupControl = props.groupItem;

          return (
            <TableRow style={{backgroundColor: '#c3c3c3'}}>
              {props.config.treeFields.map((field, index) => {
                if (index === 0) {
                  return (
                    <TableCell>
                      <span>{group[groupControl].toUpperCase()}</span>
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell>
                      <span></span>
                    </TableCell>
                  );
                }
              })}
              <TableCell>
                <span></span>
              </TableCell>
            </TableRow>
          );
        };

        return (
          <>
            <GroupRow
              groupItem={item.group}
              count={props.count}
              config={{...props.config}}
            />
            <TableRow
              key={item.id}
              style={{
                color: item.active ? 'black' : '#a94442',
                backgroundColor: props.count >= 3 ? '#a94442' : '#eeeeee',
              }}>
              {props.config.treeFields.map((field, index) => {
                switch (props.config.fields[field].input) {
                  default:
                  // return <></>;
                }
                return (
                  <TableCell>
                    <div style={{display: 'flex'}}>
                      {index === 0 && props.count > 0 ? (
                        <>
                          <span
                            style={{
                              marginLeft: props.count * 32,
                              marginRight: props.count * 8,
                            }}>
                            {props.data.length - 1 === linhas ? '└──' : '├──'}
                          </span>
                        </>
                      ) : (
                        []
                      )}
                      {(() => {
                        switch (props.config.fields[field].input) {
                          case 'number':
                            return (
                              <NumberField
                                label={props.config.fields[field].label}
                                source={field}
                                initialValue={0}
                                record={item}
                              />
                            );
                          case 'boolean':
                            return (
                              <BooleanField
                                label={props.config.fields[field].label}
                                {...(props.config.fields[field].disabled
                                  ? {disabled: true}
                                  : null)}
                                initialValue={false}
                                source={field}
                                record={item}
                              />
                            );
                          default:
                            return (
                              <TextField
                                label={props.config.fields[field].label}
                                {...(props.config.fields[field].disabled
                                  ? {disabled: true}
                                  : null)}
                                source={field}
                                record={item}
                              />
                            );
                        }
                      })()}
                    </div>
                  </TableCell>
                );
              })}
              {/* <TableCell key={`${item.id}`}>
                {props.count > 0 ? (
                  <span
                    style={{
                      marginLeft: props.count * 32 + 32,
                      marginRight: props.count * 16,
                    }}>
                    {props.data.length - 1 === linhas ? '└──' : '├──'}
                  </span>
                ) : (
                  <span
                    style={{
                      marginLeft: 32,
                    }}></span>
                )}

                <TextField source="title" record={item} />

                {props.count >= 3 ? <span> *** Excede 3 níveis ***</span> : []}
              </TableCell> */}

              {/* <TableCell>
                <span>{item.order ? item.order : '1'}</span>
              </TableCell>
              <TableCell key={`${item.id}-${item.slug}`}>
                <span>{item.slug}</span>
              </TableCell>
              <TableCell>
                {!item.images || item.images.length === 0 ? (
                  <BooleanField
                    label={'Imagens?'}
                    initialValue={false}
                    source={'hasImages'}
                    record={{hasImages: false}}
                  />
                ) : (
                  <span>{item.images.length}</span>
                )}
              </TableCell>
              <TableCell>
                {!item.files || item.files.length === 0 ? (
                  <BooleanField
                    label={'Files?'}
                    initialValue={false}
                    source={'hasFiles'}
                    record={{hasFiles: false}}
                  />
                ) : (
                  <span>{item.files.length}</span>
                )}
              </TableCell>
              <TableCell key={`${item.id}-${item.active}`}>
                <BooleanField
                  label={'Ativo?'}
                  initialValue={false}
                  source={'active'}
                  record={item}
                />
              </TableCell> */}
              <TableCell key={`${item.id}-edit`}>
                <EditButton
                  basePath={props.basePath}
                  label={'Editar'}
                  source={'id'}
                  record={item}
                />
              </TableCell>
            </TableRow>
            {item.child ? (
              <Row
                data={item.child}
                count={props.count + 1}
                config={{...props.config}}
              />
            ) : (
              []
            )}
          </>
        );
      });
    };

    return (
      <Table>
        <TableHead>
          <Head data={Object.values(props.data)} config={{...props.config}} />
        </TableHead>
        <TableBody>
          <Row data={data2} count={0} config={{...props.config}} />
        </TableBody>
      </Table>
    );
  };

  return (
    <Fragment>
      <ListAdmin
        exporter={exporter}
        {...add}
        perPage={props.resource === 'help' ? 10000 : 20}
        filters={<ListFilter {...props} />}
        sort={{
          field: props.config.sortByField ?? 'id',
          order: props.config.sortByOrder ?? 'asc',
        }}
        title={<p style={{display: 'none'}}></p>}
        {...props}
        pagination={<Pagination />}
        actions={<ListActions {...props} />}>
        {props.config.tree ? (
          <TreeGrid config={{...props.config}} />
        ) : (
          <Datagrid
            width={'100%'}
            // rowClick={'show'}
            expand={<View {...props} />}>
            {props.fields
              ? Object.keys(props.fields).map(field => {
                  if (!props.fields[field].list) {
                    return;
                  }
                  switch (props.fields[field].input) {
                    case 'date':
                      return (
                        <DateField
                          label={props.fields[field].label}
                          source={field}
                          showTime={false}
                        />
                      );
                    case 'datetime':
                      return (
                        <DateField
                          label={props.fields[field].label}
                          source={field}
                          showTime={true}
                        />
                      );
                    case 'select':
                      return (
                        <SelectField
                          label={props.fields[field].label}
                          choices={props.fields[field].choices}
                          source={field}
                        />
                      );
                    case 'text':
                    case 'transactionsstatus':
                      return (
                        <TextField
                          label={props.fields[field].label}
                          {...(props.fields[field].disabled
                            ? {disabled: true}
                            : null)}
                          source={field}
                        />
                      );
                    case 'consolelinks':
                      return (
                        <ConsoleLinksField
                          label={props.fields[field].label}
                          fields={props.fields[field].fields}
                        />
                      );
                    case 'deployversions':
                      return (
                        <DeployVersionsField
                          label={props.fields[field].label}
                          fields={props.fields[field].fields}
                        />
                      );
                    case 'transactionprint':
                      return <ButtonTransactionPrint />;
                    case 'reference':
                      return (
                        <ReferenceCustomField
                          label={props.fields[field].label}
                          source={field}
                          {...props.fields[field]}
                        />
                      );
                    case 'boolean':
                      return (
                        <BooleanField
                          label={props.fields[field].label}
                          initialValue={false}
                          source={field}
                        />
                      );
                    case 'number':
                      return (
                        <NumberField
                          label={props.fields[field].label}
                          source={field}
                        />
                      );
                    case 'real':
                      return (
                        <NumberField
                          label={props.fields[field].label}
                          source={field}
                          options={{style: 'currency', currency: 'BRL'}}
                        />
                      );
                    case 'image':
                      return (
                        <ImageField
                          title="title"
                          label={props.fields[field].label}
                          source={
                            props.fields[field].imageUrl
                              ? field
                              : field + '.uri'
                          }
                        />
                      );
                    case 'images':
                      return (
                        <ImageField
                          title="title"
                          label={props.fields[field].label}
                          src="uri"
                          source={field}
                        />
                      );
                  }
                })
              : null}
            {props.config.edit && (
              <EditButton
                {...props}
                basePath={props.basePath}
                label={'Editar'}
              />
            )}
            <ShowButton {...props} basePath={props.basePath} label={'Ver'} />
          </Datagrid>
        )}
      </ListAdmin>
      <Route path={`/${props.resource}/create`} exact={true}>
        {({match}) => <Create {...props} open={!!match} />}
      </Route>
      <Route path={`/${props.resource}/:id`} exact={true}>
        {({match}) => {
          const isMatch = match && match.params && match.params.id !== 'create';

          return (
            <>
              {isMatch && props.config.edit && (
                <Edit
                  open={true}
                  id={isMatch ? match.params.id : null}
                  {...props}
                />
              )}
              {isMatch && !props.config.edit && (
                <Show
                  open={true}
                  id={isMatch ? match.params.id : null}
                  {...props}
                />
              )}
            </>
          );
        }}
      </Route>
      <Route path={`/${props.resource}/:id/show`}>
        {({match}) => {
          const isMatch = match && match.params && match.params.id !== 'create';

          return (
            <>
              {isMatch && (
                <Show
                  open={true}
                  id={isMatch ? match.params.id : null}
                  {...props}
                />
              )}
            </>
          );
        }}
      </Route>
    </Fragment>
  );
});

export const Show = props => {
  return <CreateEditForm type={'Show'} {...props} />;
};

export const Edit = props => {
  return <CreateEditForm type={'Edit'} {...props} />;
};

export const Create = props => {
  return <CreateEditForm type={'Create'} {...props} />;
};

export const CreateEditForm = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const FormType =
    props.type === 'Create'
      ? CreateAdmin
      : props.type === 'Edit'
      ? EditAdmin
      : SimpleShowLayout;
  const handleClose = () => {
    // setOpen(false);
    props.push(props.basePath);
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useStyles();

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      fullScreen={!matches}
      fullWidth={true}
      maxWidth={'md'}
      scroll={'paper'}
      fullScreen={true}
      aria-labelledby="form-dialog-title">
      <DialogTitle
        id="form-dialog-title"
        style={{justifyContent: 'space-between'}}>
        {props.config.label}
        <Button
          startIcon={<CloseIcon />}
          onClick={handleClose}
          color="primary"
          style={{position: 'absolute', right: 20}}>
          Sair
        </Button>
      </DialogTitle>
      {/* <DialogActions>
        <Button startIcon={<CloseIcon />} onClick={handleClose} color="primary">
          Sair
        </Button>
      </DialogActions> */}
      <DialogContent>
        {['Create', 'Edit'].includes(props.type) ? (
          <FormType
            title={<TextField />}
            {...props}
            onFailure={error => {
              // alert('erro' + JSON.stringify(error.body.message));
              notify(error.body.message, 'warning');
            }}
            undoable={false}
            onSuccess={() => {
              if (props.type === 'Create') {
                notify('Item adicionado com sucesso!', 'info');
              } else {
                notify('Item atualizado com sucesso!', 'info');
              }
              redirect('list', props.basePath);
            }}>
            <SimpleForm
              submitOnEnter={false}
              redirect="show"
              variant="outlined"
              toolbar={
                <Toolbar {...props} style={{justifyContent: 'space-between'}}>
                  {props.config.add && props.type !== 'Create' && (
                    <CloneButton />
                  )}
                  <SaveButton
                  // label="post.action.save_and_show"
                  />
                  {/* <SaveButton
                    label="post.action.save_and_add"
                    redirect={false}
                    submitOnEnter={false}
                    variant="text"
                  /> */}
                </Toolbar>
              }>
              {Object.keys(props.fields).map(field => {
                let fieldProps = {...props.fields[field].props};
                if (fieldProps.inline) {
                  fieldProps.formClassName = classes.inlineBlock;
                }
                switch (props.fields[field].input) {
                  case 'datetime':
                    return (
                      <DateTimeInput
                        label={props.fields[field].label}
                        source={field}
                      />
                    );
                  case 'reference':
                    return (
                      <ReferenceCustomInput
                        {...props.fields[field]}
                        source={field}
                      />
                    );
                  case 'title':
                    return (
                      <Typography variant="h6">
                        {props.fields[field].label}
                      </Typography>
                    );
                  case 'text':
                    return (
                      <TextInput
                        label={props.fields[field].label}
                        source={field}
                        {...(props.fields[field].disabled
                          ? {disabled: true}
                          : null)}
                        initialValue={''}
                        {...fieldProps}
                      />
                    );
                  case 'textarea':
                    return (
                      <TextInput
                        label={props.fields[field].label}
                        source={field}
                        multiline={true}
                        initialValue={''}
                        {...fieldProps}
                      />
                    );
                  case 'markdown':
                    return (
                      <MarkdownInput
                        label={props.fields[field].label}
                        source={field}
                        initialValue={''}
                      />
                    );
                  case 'boolean':
                    return (
                      <BooleanInput
                        label={props.fields[field].label}
                        source={field}
                        initialValue={
                          typeof props.fields[field].initialValue !==
                          'undefined'
                            ? props.fields[field].initialValue
                            : false
                        }
                        {...fieldProps}
                      />
                    );
                  case 'number':
                    return (
                      <NumberInput
                        label={props.fields[field].label}
                        source={field}
                        initialValue={0}
                        {...fieldProps}
                      />
                    );
                  case 'real':
                    return (
                      <NumberInput
                        label={props.fields[field].label}
                        source={field}
                        initialValue={0}
                        {...fieldProps}
                      />
                    );
                  // case 'checkbox':
                  //   return (
                  //     <CheckboxGroupInput
                  //       label={props.fields[field].label}
                  //       source={field}
                  //       choices={[
                  //         {id: 'owner', name: 'Administrador'},
                  //         {id: 'anonymous', name: 'Usuário'},
                  //       ]}
                  //     />
                  //   );
                  case 'select':
                    return (
                      <SelectInput
                        label={props.fields[field].label}
                        source={field}
                        choices={props.fields[field].choices}
                        initialValue={props.fields[field].choices[0].id}
                        {...fieldProps}
                      />
                    );
                  case 'array':
                    return (
                      <ArrayInput
                        label={props.fields[field].label}
                        source={
                          (props.fields[field].objectParentName
                            ? props.fields[field].objectParentName + '.'
                            : '') +
                          field +
                          (props.fields[field].objectChildName
                            ? '.' + props.fields[field].objectChildName
                            : '')
                        }>
                        <SimpleFormIterator>
                          {mountArrayInputs(props.fields[field].fields)}
                        </SimpleFormIterator>
                      </ArrayInput>
                    );

                  case 'image':
                    return (
                      <ImageInput
                        label={props.fields[field].label}
                        source={field}
                        multiple={false}
                        labelSingle={'Arraste as imagens ou clique e selecione'}
                        accept="image/*">
                        <ImageField source={'uri'} title="title" />
                      </ImageInput>
                    );
                  case 'images':
                    return (
                      <ImageInput
                        label={props.fields[field].label}
                        labelMultiple={
                          'Arraste as imagens ou clique e selecione'
                        }
                        source={field}
                        multiple={true}
                        accept="image/*">
                        <ImageField source="uri" title="title" />
                      </ImageInput>
                    );
                  case 'files':
                    return (
                      <FileInput
                        label={props.fields[field].label}
                        labelMultiple={
                          'Arraste os arquivos ou clique e selecione'
                        }
                        source={field}
                        multiple={true}>
                        <FileField source="uri" title="title" />
                      </FileInput>
                    );
                  case 'bpmn':
                    return (
                      <BpmnInput
                        label={props.fields[field].label}
                        source={field}
                        {...fieldProps}
                      />
                    );
                }
              })}
            </SimpleForm>
          </FormType>
        ) : (
          <View {...props} />
        )}
      </DialogContent>
    </Dialog>
  );
};

const View = props => {
  return (
    <ShowAdmin
      actions={<ViewActions {...props} />}
      title={<TextField />}
      {...props}>
      <ViewIterator iterator={SimpleShowLayout} {...props} />
      {/* <TabbedShowLayout>
        <ViewIterator
          iterator={Tab}
          label={'Info'}
          path=""
          {...props}
          fields={_.omit(props.fields, [
            'transactions',
            'addresses',
            'paymentMethods',
          ])}
        />
        <ViewIterator
          iterator={Tab}
          label={'Métodos de Pagamento'}
          path="payment-methods"
          fields={_.pick(props.fields, ['paymentMethods'])}
        />
        <ViewIterator
          iterator={Tab}
          label={'Endereços'}
          path="addresses"
          fields={_.pick(props.fields, ['addresses'])}
        />
        <ViewIterator
          iterator={Tab}
          label={'Compras'}
          path="transactions"
          fields={_.pick(props.fields, ['transactions'])}
        />
      </TabbedShowLayout> */}
    </ShowAdmin>
  );
};

const ViewIterator = props => {
  const Iterator = props.iterator;
  const classes = useStyles();
  return (
    <Iterator {...props}>
      {props.fields
        ? Object.keys(props.fields).map(field => {
            let fieldProps = {...props.fields[field].props};
            if (fieldProps.inline) {
              fieldProps.className = classes.inlineBlock;
            }
            switch (props.fields[field].input) {
              case 'date':
                return (
                  <DateField
                    label={props.fields[field].label}
                    source={field}
                    showTime={false}
                    {...fieldProps}
                  />
                );
              case 'datetime':
                return (
                  <DateField
                    label={props.fields[field].label}
                    source={field}
                    showTime={true}
                    {...fieldProps}
                  />
                );
              case 'title':
                return (
                  <Typography variant="h6">
                    {props.fields[field].label}
                  </Typography>
                );
              case 'text':
                return (
                  <TextField
                    label={props.fields[field].label}
                    source={field}
                    {...fieldProps}
                  />
                );
              case 'textarea':
                return (
                  <TextField
                    label={props.fields[field].label}
                    source={field}
                    {...fieldProps}
                  />
                );
              case 'products':
                return (
                  <ProductsField
                    label={props.fields[field].label}
                    source={field}
                  />
                );
              case 'address':
                return (
                  <AddressField
                    label={props.fields[field].label}
                    source={field}
                    {...fieldProps}
                  />
                );
              case 'markdown':
                return (
                  <MarkdownField
                    label={props.fields[field].label}
                    source={field}
                    {...fieldProps}
                  />
                );
              case 'boolean':
                return (
                  <BooleanField
                    label={props.fields[field].label}
                    source={field}
                    initialValue={false}
                    {...fieldProps}
                  />
                );
              case 'select':
                return (
                  <SelectField
                    label={props.fields[field].label}
                    choices={props.fields[field].choices}
                    source={field}
                  />
                );
              case 'number':
                return (
                  <NumberField
                    label={props.fields[field].label}
                    source={field}
                    {...fieldProps}
                  />
                );
              case 'real':
                return (
                  <NumberField
                    label={props.fields[field].label}
                    source={field}
                    options={{style: 'currency', currency: 'BRL'}}
                    {...fieldProps}
                  />
                );
              case 'image':
                return (
                  <ImageField
                    title="title"
                    label={props.fields[field].label}
                    source={
                      props.fields[field].imageUrl ? field : field + '.uri'
                    }
                  />
                );
              case 'array':
                return (
                  <ArrayField label={props.fields[field].label} source={field}>
                    <Datagrid>
                      {props.fields[field].fields.key && (
                        <TextField source={'key'} />
                      )}
                      {props.fields[field].fields.name && (
                        <TextField source={'name'} />
                      )}
                      {props.fields[field].fields.value && (
                        <TextField source={'value'} />
                      )}
                      {props.fields[field].fields.sku && (
                        <TextField source={'sku'} />
                      )}
                    </Datagrid>
                  </ArrayField>
                );
              case 'images':
                return (
                  <ImageField
                    title="title"
                    label={props.fields[field].label}
                    src="uri"
                    source={field}
                  />
                );
              case 'reference':
                return (
                  <ReferenceCustomField
                    label={props.fields[field].label}
                    {...props.fields[field]}
                    source={field}
                    {...fieldProps}
                  />
                );
              case 'referencemany':
                return (
                  <ReferenceManyField
                    label={props.fields[field].label}
                    {..._.pick(props.fields[field].props, [
                      'reference',
                      'target',
                    ])}>
                    <ViewIterator
                      iterator={Datagrid}
                      fields={props.fields[field].fields}
                    />
                  </ReferenceManyField>
                );
              case 'transactionsstatus':
                return (
                  <TransactionsStatusField
                    label={props.fields[field].label}
                    fields={props.fields[field].fields}
                    choices={props.fields[field].choices}
                    {...fieldProps}
                  />
                );
              case 'consolelinks':
                return (
                  <ConsoleLinksField
                    label={props.fields[field].label}
                    fields={props.fields[field].fields}
                    {...fieldProps}
                  />
                );
              case 'deployversions':
                return (
                  <DeployVersionsField
                    label={props.fields[field].label}
                    fields={props.fields[field].fields}
                  />
                );
              case 'bpmn':
                return (
                  <BpmnField
                    label={props.fields[field].label}
                    fields={props.fields[field].fields}
                    {...fieldProps}
                  />
                );
            }
          })
        : null}
    </Iterator>
  );
};
