// in src/MyLayout.js
// import {Layout as LayoutAdmin} from 'react-admin';
// import {
//   Box,
//   Container,
//   Grid,
//   Paper,
//   Typography,
//   Button,
// } from '@material-ui/core';
// import ThemeConfig from '../../../ThemeLandingPage.json';
// import MyMenu from './MyMenu';
// import MyNotification from './MyNotification';
import QRCode from 'qrcode.react';
import AppConfig from '../../../Config.json';
// var QRCode = require('qrcode.react');

const Dashboard = props => {
  console.log(props);
  return (
    <>
      {/* <iframe
        title={'App'}
        src={`https://${process.env.REACT_APP_DOMAIN.replace('console.', '')}`}
        style={{
          width: '100%',
          height: '100vh',
          border: 0,
        }}
      /> */}
      <div style={{padding: 20, textAlign: 'center'}}>
        <QRCode
          value={`https://${AppConfig.app.hosting}`}
          size={2000}
          style={{width: 200, height: 200}}
        />
        <p>{`https://${AppConfig.app.hosting}`}</p>
      </div>
      {/* <Container disableGutters={false}>
    <Grid container spacing={2}>
      <Grid item alignContent={'center'} xs={12}>
        <Typography variant={'h4'}>{ThemeConfig.headerText}</Typography>
      </Grid>
      {props.modules.map((item) => {
        let Icon = item.icon;
        return (
          <Grid item xs={12} md={4}>
            <Typography variant={'h6'}>{item.label}</Typography>
            <Paper>
              {item.icon && <Icon />}
              <Button>Ir</Button>
            </Paper>
          </Grid>
        );
      })}
    </Grid>
  </Container> */}
    </>
  );
};

export default Dashboard;
