import {FirebaseAuthProvider} from 'react-admin-firebase';
import FirebaseConfig from '../../firebase.config.json';

const axios = require('axios');
const firebase = require('firebase/app');
const _ = require('lodash');

const api = axios.create({
  baseURL:
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
      ? `http://localhost:5001/${FirebaseConfig.projectId}/us-central1/Api/api`
      : `https://${process.env.REACT_APP_DOMAIN.replace('console.', '')}/api`,
});
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
const FirebaseToken = async () => {
  return new Promise(async (resolve, reject) => {
    do {
      if (firebase.auth().currentUser) {
        return resolve(await firebase.auth().currentUser.getIdToken());
      }
      await sleep(100);
    } while (true);
  });
};

export const GetId = (length = 28) => {
  var result = '';
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

let cursors = {};
export const DataProvider = {
  getList: (resource, params) => {
    return new Promise(async (resolve, reject) => {
      await FirebaseToken();
      let headers = {
        Console: 'active',
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await FirebaseToken()}`,
      };

      // cursor defined
      if (cursors[`${resource}-${params.pagination.page}`]) {
        params.pagination.cursor =
          cursors[`${resource}-${params.pagination.page}`];
      } else {
        params.pagination.cursor = null;
      }

      return api
        .get(resource, {
          params: params,
          headers: headers,
        })
        .then(response => {
          let data = {
            data: response.data.data,
            total:
              response.data.data.length >= params.pagination.perPage
                ? (params.pagination.page + 1) * params.pagination.perPage
                : params.pagination.page * params.pagination.perPage,
          };
          if (data.total && response.data.data[response.data.data.length - 1]) {
            cursors[`${resource}-${params.pagination.page + 1}`] =
              response.data.data[response.data.data.length - 1].id;
          }
          console.log('luann response - ' + resource, data);
          return resolve(data);
        })
        .catch(error => {
          console.log(error);
        });
    });
  },
  getOne: (resource, params) => {
    return new Promise(async (resolve, reject) => {
      await FirebaseToken();
      let headers = {
        Console: 'active',
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await FirebaseToken()}`,
      };

      return api
        .get(`${resource}/${params.id}`, {
          headers: headers,
        })
        .then(response => {
          return resolve({
            data: response.data.data,
          });
        })
        .catch(error => {
          console.log(error);
        });
    });
  },
  getMany: (resource, params) => {
    console.log('import', params, resource);
    return new Promise(async (resolve, reject) => {
      let promises = [];
      params.ids.forEach(async item => {
        promises.push(
          new Promise(async (resolve, reject) => {
            const response = await DataProvider.getOne(resource, {id: item});
            return resolve(response.data);
          }),
        );
      });
      const promisesResponse = await Promise.all(promises);
      console.log('promisesResponse', promisesResponse);
      return resolve({
        data: promisesResponse.filter(item => {
          if (item && item.id) {
            return item;
          }
        }),
      });
      // return resolve({data: values});
    });
  },
  getManyReference: (resource, params) => {
    return new Promise(async (resolve, reject) => {
      await FirebaseToken();
      let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await FirebaseToken()}`,
      };

      // cursor defined
      if (cursors[`${resource}-${params.target}-${params.pagination.page}`]) {
        params.pagination.cursor =
          cursors[`${resource}-${params.target}-${params.pagination.page}`];
      } else {
        params.pagination.cursor = null;
      }
      // params.console = true;
      return api
        .get(`${resource}/${params.id}/${params.target}`, {
          // params: {id: params.id},
          headers: headers,
        })
        .then(response => {
          // console.log(
          //   'response',
          //   response.data.data.length >= params.pagination.perPage
          //     ? params.pagination.page * params.pagination.perPage
          //     : (params.pagination.page - 1) * params.pagination.perPage,
          // );
          let data = {
            data: response.data.data,
            total:
              response.data.data.length >= params.pagination.perPage
                ? (params.pagination.page + 1) * params.pagination.perPage
                : params.pagination.page * params.pagination.perPage,
          };

          if (data.total && response.data.data[response.data.data.length - 1]) {
            cursors[
              `${resource}-${params.target}-${params.pagination.page + 1}`
            ] = response.data.data[response.data.data.length - 1].id;
          }
          return resolve(data);
        })
        .catch(error => {
          console.log(error);
        });
    });
    console.log(params, resource, 'getMany');
    return new Promise((resolve, reject) => {});
  },
  create: (resource, params) => {
    return new Promise(async (resolve, reject) => {
      let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await FirebaseToken()}`,
      };

      params = await UploadImages(resource, params);
      ///se image for um objeto, salva somente a URL...
      ///só salva o objeto quando é multiplas imagens, no prop images
      if (
        typeof params.data.image !== 'undefined' &&
        typeof params.data.image === 'object'
      ) {
        params.data.image = params.data.image.uri ? params.data.image.uri : '';
      }

      return api
        .post(resource, params.data, {
          headers: headers,
        })
        .then(response => {
          // console.log(response);
          if (response.data.ok) {
            return resolve({data: {id: response.data.id, ...params.data}});
          } else {
            return reject({
              status: 500,
              body: response.data,
              name: 'HttpError',
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    });
  },
  update: (resource, params) => {
    // console.log('update', params, resource);
    return new Promise(async (resolve, reject) => {
      let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await FirebaseToken()}`,
      };

      params = await UploadImages(resource, params);

      ///se image for um objeto, salva somente a URL...
      ///só salva o objeto quando é multiplas imagens, no prop images
      if (
        typeof params.data.image !== 'undefined' &&
        params.data.image !== null &&
        typeof params.data.image === 'object'
      ) {
        params.data.image = params.data.image.uri ? params.data.image.uri : '';
      }
      return api
        .put(`${resource}/${params.id}`, params.data, {
          headers: headers,
        })
        .then(response => {
          console.log(response);
          if (response.data.ok) {
            return resolve({data: {id: response.data.id, ...params.data}});
          } else {
            return reject({
              status: 500,
              body: response.data,
              name: 'HttpError',
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    });
  },
  updateMany: (resource, params) => {
    return new Promise(async (resolve, reject) => {
      console.log('import', params, resource);
      let promises = [];
      params.data.map(item => {
        promises.push(DataProvider.update(resource, {id: item.id, data: item}));
      });
      await Promise.all(promises);
      return resolve({data: promises});
    });
  },
  delete: (resource, params) => {
    return new Promise(async (resolve, reject) => {
      let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await FirebaseToken()}`,
      };
      return api
        .delete(`${resource}/${params.id}`, {
          headers: headers,
          // params: params,
        })
        .then(response => {
          return resolve({data: []});
        })
        .catch(error => {
          console.log(error);
        });
    });
  },
  deleteMany: async (resource, params) => {
    return new Promise(async (resolve, reject) => {
      let promises = [];
      params.ids.map(item => {
        promises.push(DataProvider.delete(resource, {id: item}));
      });
      await Promise.all(promises);
      return resolve({data: []});
    });
  },
};

const UploadImages = async (resource, params) => {
  return new Promise(async (resolveComplete, rejectComplete) => {
    let files = {};
    let range = (objeto, path = '') => {
      if (!objeto) {
        return;
      }
      if (objeto && objeto.rawFile && objeto.rawFile instanceof File) {
        files[path] = {};
      }
      Object.keys(objeto).map(item => {
        if (typeof objeto[item] === 'object') {
          return range(objeto[item], (path ? path + '.' : '') + item);
        }
      });
    };
    range(params.data);

    //se for tipo imag

    let promises = [];
    Object.keys(files).map(path => {
      promises.push(
        new Promise(async (resolveAll, reject) => {
          let refimage = firebase
            .storage()
            .ref()
            .child(`${resource}/${GetId()}`);
          await refimage
            .put(_.get(params.data, path).rawFile)
            .then(async snapshot => {
              return new Promise(async (resolve, reject) => {
                ///salva nos parametros a URL de download do storage..
                _.set(
                  params.data,
                  path + '.uri',
                  await snapshot.ref.getDownloadURL(),
                );

                //se for imagem, obtem as dimensões, senao, encerrra...
                if (
                  path.split('.')[0] === 'images' ||
                  path.split('.')[0] === 'image'
                ) {
                  let imageSize = new Image();
                  imageSize.onload = function () {
                    let newImage = _.omit(_.get(params.data, path), [
                      'rawFile',
                    ]);
                    newImage.width = this.width;
                    newImage.height = this.height;
                    newImage.orientation =
                      this.width == this.height
                        ? 'square'
                        : this.width > this.height
                        ? 'landscape'
                        : 'portrait';
                    _.set(params.data, path, newImage);
                    resolveAll(true);
                    resolve(true);
                  };
                  imageSize.src = _.get(params.data, path).uri;
                } else {
                  let newFile = _.omit(_.get(params.data, path), ['rawFile']);
                  _.set(params.data, path, newFile);
                  resolveAll(true);
                  resolve(true);
                }
              });
            })
            .catch(e => {
              console.log(e);
            });
        }),
      );
    });
    if (promises) {
      await Promise.all(promises);
    }
    return resolveComplete(params);
  });
};

export const AuthProvider = FirebaseAuthProvider(
  require('../../firebase.config.json'),
  {},
);

export const AuthProvider2 = {
  login: params => {
    return new Promise((resolve, reject) => {
      console.log(params);
      return resolve(true);
    });
  },
  logout: params => {
    return new Promise((resolve, reject) => {
      console.log(params);
      return resolve(true);
    });
  },
  checkAuth: params => {
    return new Promise((resolve, reject) => {
      console.log(params);
      return resolve(true);
    });
  },
  checkError: error => {
    return new Promise((resolve, reject) => {
      console.log(error);
      return resolve(true);
    });
  },
  getPermissions: params => {
    return new Promise((resolve, reject) => {
      console.log(params);
      return resolve(true);
    });
  },
  getIdentity: () => {
    return new Promise((resolve, reject) => {
      return resolve(true);
    });
  },
};
