// in src/MyLayout.js
import {Layout as LayoutAdmin} from 'react-admin';
import AppBar from './Layout/AppBar';
import Menu from './Layout/Menu';
// import MyMenu from './MyMenu';
// import MyNotification from './MyNotification';

const Layout = (props) => (
  <LayoutAdmin
    {...props}
    appBar={AppBar}
    menu={Menu}
    // notification={MyNotification}
  />
);

export default Layout;
