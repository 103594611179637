import * as React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {ChipField, ReferenceField} from 'react-admin';

const ReferenceCustomField = props => {
  if (props.referenceType === 'stores') {
    if (props.record.accountType && props.record.accountId) {
      return (
        <ReferenceField
          label={props.label}
          source="accountId"
          reference={'stores'}>
          <ChipField size="small" source="name" />
        </ReferenceField>
      );
    }
  }

  //exibir cluster das stories
  if (props.referenceType === 'cluster') {
    if (props.record.cluster && props.record.cluster !== '') {
      return (
        <ReferenceField
          label={props.label}
          source="cluster"
          reference={'stores'}>
          <ChipField size="small" source="name" />
        </ReferenceField>
      );
    }
  }

  ///exibir parentId do help
  if (props.referenceType === 'help') {
    if (props.record.parentId && props.record.parentId !== '') {
      return (
        <ReferenceField
          label={props.label}
          source="parentId"
          reference={'help'}
          {...props}>
          <ChipField size="small" source="title" />
        </ReferenceField>
      );
    }
  }

  ///exibir parentId do marketing
  if (props.referenceType === 'marketing') {
    if (props.record.parentId && props.record.parentId !== '') {
      return (
        <ReferenceField
          label={props.label}
          source="parentId"
          reference={'marketing'}
          {...props}>
          <ChipField size="small" source="title" />
        </ReferenceField>
      );
    }
  }

  ///exibir parentId do categories
  if (props.referenceType === 'categories') {
    ///tratar se o record existe etc...
    if (props.record.categoryId && props.record.categoryId !== '') {
      return (
        <ReferenceField
          label={props.label}
          source="categoryId"
          reference={'categories'}
          {...props}>
          <ChipField size="small" source="name" />
        </ReferenceField>
      );
    }
  }
  return null;
};
ReferenceCustomField.defaultProps = {
  addLabel: true,
};
ReferenceCustomField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default ReferenceCustomField;
