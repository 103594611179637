import * as React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  ChipField,
  ReferenceField,
  useDataProvider,
  useRefresh,
} from 'react-admin';
import Chip from '@material-ui/core/Chip';
// import ButtonGroup from '@material-ui/core/ButtonGroup';
import {Button, ButtonGroup} from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import AppleIcon from '@material-ui/icons/Apple';
import CodeIcon from '@material-ui/icons/Code';
import AndroidIcon from '@material-ui/icons/Android';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const ConsoleLinksField = (props) => {
  const dataProvider = useDataProvider();
  const [status, setStatus] = React.useState({
    sites: props.record.deploy?.sites?.status,
    apps: props.record.deploy?.apps?.status,
    android: props.record.deploy?.android?.status,
  });
  React.useEffect(() => {
    setStatus({
      sites: props.record.deploy?.sites?.status,
      ios: props.record.deploy?.ios?.status,
      android: props.record.deploy?.android?.status,
    });
  }, [props.record]);
  const myRefresh = useRefresh();
  const handleChange = (type) => {
    if (!window.confirm('Deseja colocar o BOB para trabalhar?')) {
      return;
    }
    if (status[type] === 'pending') {
      alert('Em execução');
    } else {
      setStatus({
        ...status,
        [type]: 'pending',
      });

      dataProvider.create('apps/app/deploy', {
        id: props.record.id,
        data: {
          id: props.record.id,
          [type]: 1,
        },
      });
      setTimeout(function () {
        myRefresh();
      }, 3000);
    }
  };
  if (!props.record.hosting) {
    return null;
  }
  return (
    <div>
      Acessar Sites:
      <br />
      <br />
      <ButtonGroup
        size="small"
        color="primary"
        aria-label="large outlined secondary button group">
        <Button
          onClick={(e) => {
            window.open(`https://${props.record.hosting}`, '_blank');
            e.preventDefault();
          }}>
          App
        </Button>
        <Button
          onClick={(e) => {
            window.open(`https://console.${props.record.hosting}`, '_blank');
            e.preventDefault();
          }}>
          Console
        </Button>
      </ButtonGroup>
      <br></br>
      <br></br>
      App Store:
      <br></br>
      <br></br>
      <ButtonGroup
        size="small"
        color="primary"
        aria-label="large outlined secondary button group">
        <Button
          onClick={(e) => {
            window.open(
              `https://play.google.com/store/apps/details?id=${props.record.bundleId}`,
              '_blank',
            );
            e.preventDefault();
          }}>
          <AndroidIcon></AndroidIcon>
        </Button>
        <Button
          onClick={(e) => {
            window.open(
              `https://apps.apple.com/br/app/id${props.record.appleStoreId}`,
              '_blank',
            );
            e.preventDefault();
          }}>
          <AppleIcon></AppleIcon>
        </Button>
      </ButtonGroup>
      <br />
      <br />
      Executar Deploys:
      <br />
      <br />
      <ButtonGroup
        size="small"
        color="primary"
        aria-label="large outlined secondary button group">
        <Button
          onClick={(e) => {
            handleChange('android');
          }}>
          <AndroidIcon></AndroidIcon>
        </Button>
        <Button
          onClick={(e) => {
            handleChange('ios');
          }}>
          <AppleIcon></AppleIcon>
        </Button>
        <Button
          onClick={(e) => {
            handleChange('sites');
          }}>
          <CodeIcon></CodeIcon>
        </Button>
      </ButtonGroup>
    </div>
  );
};
ConsoleLinksField.defaultProps = {
  addLabel: true,
};
ConsoleLinksField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default ConsoleLinksField;
