import React, {cloneElement, useMemo, Fragment} from 'react';
import _ from 'lodash';
import {
  Filter,
  ImageField,
  TextInput,
  DateTimeInput,
  BooleanInput,
  SelectInput,
  SimpleFormIterator,
  ArrayInput,
  ImageInput,
  NumberInput,
} from 'react-admin';
import MarkdownInput from 'ra-input-markdown';

import Typography from '@material-ui/core/Typography';

const ListFilter = (props) => {
  return (
    <>
      <Filter {...props}>
        {Object.keys(props.fields).map((field) => {
          let fieldProps = {...props.fields[field].props};
          if (!props.fields[field].filter) {
            return;
          }
          switch (props.fields[field].input) {
            case 'datetime':
              return (
                <DateTimeInput
                  label={props.fields[field].label}
                  source={field}
                />
              );
            case 'title':
              return (
                <Typography variant="h6">
                  {props.fields[field].label}
                </Typography>
              );
            case 'text':
              return (
                <TextInput
                  label={props.fields[field].label}
                  source={field}
                  {...fieldProps}
                />
              );
            case 'textarea':
              return (
                <TextInput
                  label={props.fields[field].label}
                  source={field}
                  multiline={true}
                  {...fieldProps}
                />
              );
            case 'markdown':
              return (
                <MarkdownInput
                  label={props.fields[field].label}
                  source={field}
                />
              );
            case 'image':
            case 'boolean':
              return (
                <BooleanInput
                  label={props.fields[field].label}
                  source={field}
                  {...fieldProps}
                />
              );
            case 'number':
              return (
                <NumberInput
                  label={props.fields[field].label}
                  source={field}
                  {...fieldProps}
                />
              );
            case 'real':
              return (
                <NumberInput
                  label={props.fields[field].label}
                  source={field}
                  {...fieldProps}
                />
              );
            case 'select':
              return (
                <SelectInput
                  label={props.fields[field].label}
                  source={field}
                  choices={props.fields[field].choices}
                  {...fieldProps}
                />
              );
            case 'array':
              return (
                <ArrayInput label={props.fields[field].label} source={field}>
                  <SimpleFormIterator>
                    {props.fields[field].fields.key && (
                      <TextInput source={'key'} />
                    )}
                    {props.fields[field].fields.value && (
                      <TextInput source={'value'} />
                    )}
                    {props.fields[field].fields.sku && (
                      <TextInput source={'sku'} />
                    )}
                  </SimpleFormIterator>
                </ArrayInput>
              );
            case 'images':
              return (
                <ImageInput
                  label={props.fields[field].label}
                  labelMultiple={'Arraste as imagens ou clique e selecione'}
                  source={field}
                  multiple={true}
                  accept="image/*">
                  <ImageField source="uri" title="title" />
                </ImageInput>
              );
          }
        })}
      </Filter>
    </>
  );
};

export default ListFilter;
