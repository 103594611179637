// in src/Menu.js
import * as React from 'react';
// import {createElement} from 'react';
import {useSelector} from 'react-redux';
import {useMediaQuery} from '@material-ui/core';
import {MenuItemLink, getResources, useLogout} from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
// import LabelIcon from '@material-ui/icons/Label';
import LogoImg from '../../../logo.svg';

const Menu = ({onMenuClick, logout}) => {
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  const logoutHandle = useLogout();
  return (
    <div>
      <MenuItemLink
        to="/"
        primaryText={
          <div>
            <img
              alt=""
              aria-describedby={null}
              src={LogoImg}
              style={{
                width: '100%',
                height: '100%',
                marginTop: 10,
                borderRadius: open ? 10 : 2,
              }}
            />
          </div>
        }
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {resources.map((resource) => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={
            (resource.options && resource.options.label) || resource.name
          }
          leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      ))}
      {isXSmall && (
        <MenuItemLink
          key={'Logout'}
          to={`/`}
          primaryText={'Logout'}
          leftIcon={<LogoutIcon />}
          onClick={() => {
            logoutHandle();
          }}
          sidebarIsOpen={open}
        />
      )}
    </div>
  );
};

export default Menu;
