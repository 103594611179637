import React from 'react';

import BpmnJSModeler from 'bpmn-js/lib/Modeler';
import BpmnJSViewer from 'bpmn-js/lib/Viewer';
import propertiesPanelModule from 'bpmn-js-properties-panel';
import propertiesProviderModule from 'bpmn-js-properties-panel/lib/provider/camunda';
import camundaModdleDescriptor from 'camunda-bpmn-moddle/resources/camunda';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
// import BpmnJS from 'bpmn-js/dist/bpmn-navigated-viewer.production.min.js';

export default class ReactBpmn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      full: false,
    };

    this.containerRef = React.createRef();
  }

  componentDidMount() {
    const {url, diagramXML} = this.props;

    const container = this.containerRef.current;

    this.bpmnViewer = null;
    if (this.props.viewerOnly) {
      this.bpmnViewer = new BpmnJSViewer({
        container,
        ...{
          keyboard: {
            bindTo: window,
          },
        },
      });
    } else {
      this.bpmnViewer = new BpmnJSModeler({
        container,
        ...{
          additionalModules: [propertiesPanelModule, propertiesProviderModule],
          propertiesPanel: {
            parent: '#properties',
          },
          moddleExtensions: {
            camunda: camundaModdleDescriptor,
          },
          keyboard: {
            bindTo: window,
          },
        },
      });
    }

    document.querySelectorAll('#properties').forEach((item) => {
      item.addEventListener('click', (e) => {
        e.preventDefault();
      });
    });
    this.bpmnViewer.on('elements.changed', async (event) => {
      let xmlValue = await this.bpmnViewer.saveXML({format: true});

      if (xmlValue && xmlValue.xml) {
        this.props.onChange({
          target: {value: xmlValue.xml},
        });
      }
    });
    this.bpmnViewer.on('import.done', (event) => {
      const {error, warnings} = event;

      if (error) {
        return this.handleError(error);
      }

      this.bpmnViewer.get('canvas').zoom('fit-viewport', 'auto');

      return this.handleShown(warnings);
    });

    if (url) {
      return this.fetchDiagram(url);
    }

    if (this.props.value.bpmn) {
      this.displayDiagram(this.props.value.bpmn);
    } else {
      this.displayDiagram(`
      <?xml version="1.0" encoding="UTF-8"?>
      <bpmn:definitions xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" id="Definitions_0buay74" targetNamespace="http://bpmn.io/schema/bpmn" exporter="Camunda Modeler" exporterVersion="4.2.0">
        <bpmn:process id="process" isExecutable="true">
          <bpmn:startEvent id="StartEvent_1" />
        </bpmn:process>
        <bpmndi:BPMNDiagram id="BPMNDiagram_1">
          <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="process">
            <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
              <dc:Bounds x="179" y="79" width="36" height="36" />
            </bpmndi:BPMNShape>
          </bpmndi:BPMNPlane>
        </bpmndi:BPMNDiagram>
      </bpmn:definitions>
      `);
    }
  }

  componentWillUnmount() {
    this.bpmnViewer.destroy();
  }

  componentDidUpdate(prevProps, prevState) {
    const {props, state} = this;

    if (props.url !== prevProps.url) {
      return this.fetchDiagram(props.url);
    }

    const currentXML = props.diagramXML || state.diagramXML;

    const previousXML = prevProps.diagramXML || prevState.diagramXML;

    if (currentXML && currentXML !== previousXML) {
      return this.displayDiagram(currentXML);
    }
  }

  displayDiagram(diagramXML) {
    this.bpmnViewer.importXML(diagramXML);
  }

  fetchDiagram(url) {
    this.handleLoading();

    fetch(url)
      .then((response) => response.text())
      .then((text) => this.setState({diagramXML: text}))
      .catch((err) => this.handleError(err));
  }

  handleLoading() {
    const {onLoading} = this.props;

    if (onLoading) {
      onLoading();
    }
  }

  handleError(err) {
    const {onError} = this.props;

    if (onError) {
      onError(err);
    }
  }

  handleShown(warnings) {
    const {onShown} = this.props;

    if (onShown) {
      onShown(warnings);
    }
  }

  render() {
    return (
      <div
        style={{
          backgroundColor: '#EEE',
          ...(this.state.full
            ? {
                position: 'fixed',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                zIndex: 1,
              }
            : {position: 'relative'}),
        }}>
        <div
          style={{position: 'absolute', zIndex: 1}}
          onClick={() => {
            this.setState({full: !this.state.full});
          }}>
          {!this.state.full ? <FullscreenIcon /> : <FullscreenExitIcon />}
        </div>
        {/* <div
          onClick={() => {
            if (!this.state.full) {
              document.body.requestFullscreen();
            } else {
              document.exitFullscreen();
            }
            this.setState({full: !this.state.full});
          }}>
          {!this.state.full ? <FullscreenIcon /> : <FullscreenExitIcon />}
        </div> */}
        {!this.props.viewerOnly && (
          <div
            id="properties"
            style={{
              position: 'absolute',
              zIndex: 1,
              right: 0,
              width: 400,
              height: '100vh',
              backgroundColor: '#FFF',
            }}></div>
        )}

        <div
          className="react-bpmn-diagram-container"
          ref={this.containerRef}
          style={{height: '100vh'}}></div>
      </div>
    );
  }
}
