// in app.js
import * as React from 'react';
import {Route} from 'react-router-dom';
import _ from 'lodash';
import AppConfig from '../../Config.json';
import {Admin, Resource, useLocale} from 'react-admin';
import portugueseMessages from 'ra-language-portuguese';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
// This package's translations
// import * as domainMessages from 'react-admin-import-csv/i18n';

import Layout from './Layout';
import {Auth, AuthLogoutButton} from './Layout/Auth';
import Theme from './Layout/Theme';
import Dashboard from './Layout/Dashboard';
import TransactionPrint from './Layout/TransactionPrint';
import {AuthProvider, DataProvider} from './Services';
import {List, Edit, Create} from '../Forms';

import BookIcon from '@material-ui/icons/Book';
import GroupIcon from '@material-ui/icons/Group';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import StoreIcon from '@material-ui/icons/Store';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import VibrationIcon from '@material-ui/icons/Vibration';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import HelpIcon from '@material-ui/icons/Help';
import NewReleasesIcon from '@material-ui/icons/NewReleases';

import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import CategoryIcon from '@material-ui/icons/Category';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import ShareIcon from '@material-ui/icons/Share';

const locale = 'pt';
// Create messages object

const messages = {
  // Delete languages you don't need
  pt: {
    ...portugueseMessages,
    'ra.action.delete': 'Excluir',
    'ra.action.unselect': 'Remover seleção',
    'Arraste as imagens ou clique e selecione':
      'Arraste as imagens ou clique e selecione',
    ...{
      csv: {
        buttonMain: {
          label: 'Importar',
          tooltip: "Deve ser um arquivo '.csv' ou '.tsv'",
          emptyResource:
            "The 'resource' property was empty, did you pass in the {...props} to the ImportButton?",
        },
        parsing: {
          collidingIds: 'Found colliding "id" fields',
          failedValidateRow: 'CSV failed the validation requirements',
          invalidCsv: 'The documnent could not be parsed as a "csv" file',
        },
        dialogCommon: {
          subtitle:
            'Importing %{count} items from %{fileName} to "%{resource}"',
          conflictCount:
            'The resource <strong>%{resource}</strong> has <strong>%{conflictingCount}</strong> more records with conflicting ids',
          buttons: {
            cancel: 'Cancelar',
          },
        },
        dialogImport: {
          alertClose: '"%{fname}" importado',
          title: 'Importando para "%{resource}"',
          buttons: {
            replaceAllConflicts: 'Substituir os registros',
            skipAllConflicts: 'Pular esses registros',
            letmeDecide: 'Decidir cada registro',
          },
        },
        dialogDecide: {
          title: 'Importando %{id} para "%{resource}"',
          buttons: {
            replaceRow: 'Substituir o registro id=%{id}',
            addAsNewRow: 'Adicionar como novo registro (Não substituir)',
            skipDontReplace: 'Pular esse registro (Não substituir)',
          },
        },
        loading: 'Carregando...',
      },
    },
  },
};
// Create polyglot provider
const i18nProvider = polyglotI18nProvider(
  locale => (messages[locale] ? messages[locale] : messages.en),
  locale,
);

const App = props => {
  let treeGroups = {
    associates: 'Empresas',
    clusters: 'CDLs',
    users: 'Consumidores',
    files: 'Materiais',
    campaigns: 'Campanhas',
    charities: 'Instituições',
    '': 'Sem Grupo',
    null: 'Sem Grupo',
    undefined: 'Sem Grupo',
  };

  const modules = [
    {
      id: 'categories',
      label: 'Categorias',
      view: true,
      edit: true,
      add: true,
      export: false,
      delete: true,
      import: false,
      icon: props => <CategoryIcon />,
      fields: {
        name: {
          input: 'text',
          label: 'Nome',
          filter: true,
          list: true,
        },
        active: {input: 'boolean', label: 'Ativo?', list: true, filter: true},
        slug: {
          input: 'text',
          label: 'Slug',
          disabled: true,
          list: false,
        },
      },
    },
    {
      id: 'help',
      label: 'Ajuda',
      view: false,
      edit: true,
      add: true,
      export: false,
      delete: true,
      import: false,
      icon: props => <HelpIcon />,
      treeGroups: treeGroups,

      treeFields: ['title', 'order', 'slug', 'group', 'active'],
      tree: true,
      fields: {
        active: {input: 'boolean', label: 'Ativo?', list: true, filter: true},
        group: {
          input: 'select',
          label: 'Grupo de visualização',
          list: true,
          filter: true,
          choices: [
            {
              id: '',
              name: '',
            },
            {
              id: 'associates',
              name: 'Empresas',
            },
            {
              id: 'clusters',
              name: 'CDLs',
            },
            {
              id: 'users',
              name: 'Consumidores',
            },
            {
              id: 'files',
              name: 'Materiais',
            },
            {
              id: 'campaigns',
              name: 'Campanhas',
            },
            {
              id: 'charities',
              name: 'Instituições',
            },
          ],
        },

        title: {
          input: 'text',
          label: 'Titulo',
          filter: true,
          list: true,
        },

        parentId: {
          input: 'reference',
          referenceType: 'help',
          label: 'Ajuda Pai',
          list: true,
          optionText: choice => {
            return choice.title
              ? choice.title +
                  '(' +
                  (treeGroups[choice.group]
                    ? treeGroups[choice.group]
                    : 'Sem Grupo') +
                  ')'
              : '== Sem Ajuda Pai ==';
          },
        },
        order: {
          input: 'number',
          label: 'Ordem',
          filter: true,
          list: true,
        },
        description: {
          input: 'markdown',
          label: 'Descrição',
          list: false,
          props: {fullWidth: true},
        },
        shortDescription: {
          input: 'text',
          label: 'Descrição Curta',
          list: false,
          props: {fullWidth: true},
        },
        images: {
          input: 'images',
          label: 'Imagens',
        },
        files: {
          input: 'files',
          label: 'Arquivos',
        },
        slug: {
          input: 'text',
          label: 'Slug',
          disabled: true,
          list: false,
          props: {fullWidth: true},
        },
      },
    },
    {
      id: 'stories',
      label: 'Stories',
      view: false,
      edit: true,
      add: true,
      export: false,
      delete: true,
      import: false,
      icon: props => <NewReleasesIcon />,
      fields: {
        title: {
          input: 'text',
          label: 'Titulo',
          filter: true,
          list: true,
        },
        active: {input: 'boolean', label: 'Ativo?', list: true, filter: true},
        shortDescription: {
          input: 'markdown',
          label: 'Descrição Curta',
          list: false,
          props: {fullWidth: true},
        },
        description: {
          input: 'markdown',
          label: 'Descrição',
          list: false,
          props: {fullWidth: true},
        },
        stores: {
          input: 'reference',
          referenceType: 'stores',
          label: 'Lojas',
          list: false,
          filter: false,
          multiplesChoices: true,
          props: {inline: true},
        },
        clusters: {
          input: 'reference',
          referenceType: 'cluster',
          label: 'CDLs',
          list: false,
          filter: false,
          multiplesChoices: true,
          props: {inline: true},
        },
        image: {
          input: 'image',
          label: 'Imagem',
          list: false,
        },
        images: {
          input: 'images',
          label: 'Imagens',
        },
      },
    },
    {
      id: 'stores',
      label: 'Lojas',
      view: true,
      edit: true,
      add: true,
      delete: false,
      fields: {
        'title.content': {input: 'title', label: 'Dados da Loja'},
        name: {
          input: 'text',
          label: 'Nome',
          list: true,
          filter: true,
          props: {inline: true},
        },
        legalName: {
          input: 'text',
          label: 'Razao Social',
          list: false,
          filter: false,
          props: {inline: true},
        },
        slug: {
          input: 'text',
          label: 'Slug',
          disabled: true,
          list: false,
        },
        clusterSlug: {
          input: 'text',
          label: 'clusterSlug',
          disabled: true,
          list: false,
        },
        type: {
          label: 'Tipo',
          input: 'select',
          filter: true,
          list: true,
          choices: [
            {
              id: 'stores',
              name: 'Empresa',
            },
            {
              id: 'associates',
              name: 'Associado',
            },
            {
              id: 'starter',
              name: 'Starter',
            },
            {
              id: 'charities',
              name: 'Instituição',
            },
            {
              id: 'clusters',
              name: 'CDL',
            },
          ],
        },
        cluster: {
          input: 'reference',
          referenceType: 'cluster',
          label: 'CDL',
          list: true,
          filter: true,
          props: {inline: true},
          // required: true,
        },
        categoryId: {
          input: 'reference',
          referenceType: 'categories',
          label: 'Categoria',
          list: true,
          filter: true,
          props: {inline: true},
        },
        headline: {
          input: 'text',
          label: 'Headline',
        },
        taxvat: {
          input: 'text',
          label: 'CNPJ',
          filter: true,
          props: {inline: true},
        },
        openingHours: {
          input: 'textarea',
          label: 'Horário de funcionamento',
          props: {fullWidth: true},
        },
        'title.contact': {input: 'title', label: 'Dados de Contato'},
        whatsapp: {input: 'text', label: 'WhatsApp', props: {inline: true}},
        phoneNumber: {input: 'text', label: 'Telefone', props: {inline: true}},
        image: {
          input: 'image',
          label: 'Imagem Perfil',
          list: false,
          // imageUrl: true,
        },
        images: {
          input: 'images',
          label: 'Imagens',
          list: false,
        },
        sponsors: {
          input: 'images',
          label: 'Patrocinadores',
          list: false,
        },
        'title.addresse': {input: 'title', label: 'Endereço'},
        'address.zipcode': {input: 'text', label: 'CEP', props: {inline: true}},
        'address.street': {
          input: 'text',
          label: 'Rua',
          list: true,
          props: {inline: true},
        },
        'address.number': {
          input: 'text',
          label: 'Número',
          props: {inline: true},
        },
        'address.complement': {
          input: 'text',
          label: 'Complemento',
          props: {inline: true},
        },
        'address.street2': {
          input: 'text',
          label: 'Bairro',
          props: {inline: true},
        },
        'address.city': {input: 'text', label: 'Cidade', props: {inline: true}},
        'address.state': {
          input: 'select',
          label: 'Estado',
          list: true,
          choices: [
            {id: '', name: '-'},
            {
              id: 'AC',
              name: 'Acre',
            },
            {
              id: 'AL',
              name: 'Alagoas',
            },
            {id: 'AM', name: 'Amazonas'},
            {id: 'AP', name: 'Amapá'},
            {id: 'BA', name: 'Bahia'},
            {id: 'CE', name: 'Ceará'},
            {id: 'DF', name: 'Distrito Federal'},
            {id: 'ES', name: 'Espírito Santo'},
            {id: 'GO', name: 'Goiás'},
            {id: 'MA', name: 'Maranhão'},
            {id: 'MT', name: 'Mato Grosso'},
            {id: 'MS', name: 'Mato Grosso do Sul'},
            {id: 'MG', name: 'Minas Gerais'},
            {id: 'PA', name: 'Pará'},
            {id: 'PB', name: 'Paraíba'},
            {id: 'PR', name: 'Paraná'},
            {id: 'PE', name: 'Pernambuco'},
            {id: 'PI', name: 'Piauí'},
            {id: 'RJ', name: 'Rio de Janeiro'},
            {id: 'RN', name: 'Rio Grande do Norte'},
            {id: 'RO', name: 'Rondônia'},
            {id: 'RS', name: 'Rio Grande do Sul'},
            {id: 'RR', name: 'Roraima'},
            {id: 'SC', name: 'Santa Catarina'},
            {id: 'SE', name: 'Sergipe'},
            {id: 'SP', name: 'São Paulo'},
            {id: 'TO', name: 'Tocantins'},
            ,
          ],
          props: {inline: true},
        },
        coordinates: {
          input: 'text',
          label: 'Coordenadas',
          props: {inline: true},
        },
        'title.bank': {
          input: 'title',
          label: 'Dados de Bancários',
          props: {inline: true},
        },
        'bank.number': {input: 'text', label: 'Número', props: {inline: true}},
        'bank.agency': {input: 'text', label: 'Agência', props: {inline: true}},
        'bank.account': {
          input: 'text',
          label: 'Conta Corrente',
          props: {inline: true},
        },
        'bank.taxvat': {input: 'text', label: 'CNPJ', props: {inline: true}},
        'bank.name': {
          input: 'text',
          label: 'Razão Social',
          props: {inline: true},
        },
        'title.loyaltyBank': {
          input: 'title',
          label: 'Dados de Bancários CASHBACK',
          props: {inline: true},
        },
        'loyaltyBank.number': {
          input: 'text',
          label: 'Número',
          props: {inline: true},
        },
        'loyaltyBank.agency': {
          input: 'text',
          label: 'Agência',
          props: {inline: true},
        },
        'loyaltyBank.account': {
          input: 'text',
          label: 'Conta Corrente',
          props: {inline: true},
        },
        'loyaltyBank.taxvat': {
          input: 'text',
          label: 'CNPJ',
          props: {inline: true},
        },
        'loyaltyBank.name': {
          input: 'text',
          label: 'Razão Social',
          props: {inline: true},
        },
        'title.config': {
          input: 'title',
          label: 'Configurações',
          props: {inline: true},
        },
        // displayOnly: {input: 'boolean', label: 'Somente exibição'},
        showInCheckout: {input: 'boolean', label: 'Exibir no checkout?'},
        showInStoresList: {
          input: 'boolean',
          label: 'Exibir na lista de Lojas?',
        },

        status: {
          label: 'Status',
          input: 'select',
          filter: true,
          list: true,
          choices: [
            {
              id: 'active',
              name: 'Ativo',
            },
            {
              id: 'inactive',
              name: 'Inativo',
            },
            {
              id: 'pending',
              name: 'Pendente',
            },
            {id: 'soon', name: 'Em Breve'},
          ],
        },
        companyType: {
          label: 'Tipo da empresa',
          input: 'select',

          choices: [
            {
              id: 'mei',
              name: 'Micro Empreendedor Individual(MEI)',
            },
            {
              id: 'limited',
              name: 'Empresa Limitada(Ltda)',
            },
            {
              id: 'individual',
              name: 'Empresa Individual',
            },
            {
              id: 'association',
              name: 'Associação',
            },
          ],
        },
        'title.links': {input: 'title', label: 'Árvore de Links'},
        instagram: {input: 'text', label: 'Instagram', props: {inline: true}},
        facebook: {input: 'text', label: 'Facebook', props: {inline: true}},
        linkedin: {input: 'text', label: 'Linkedin', props: {inline: true}},
        site: {input: 'text', label: 'Site', props: {inline: true}},
        'title.businessRegularHours': {
          input: 'title',
          label: 'Horário de funcionamento do Estabelecimento',
        },
        'businessRegularHours.status': {
          input: 'boolean',
          label: 'Exibir na página da loja?',
        },
        'businessRegularHours.periods': {
          input: 'array',
          label: 'Dia de funcionamento',
          fields: {
            openDay: {
              input: 'select',
              label: 'Dia da Semana Inicio',
              choices: [
                {id: 'monday', name: 'Segunda-Feira'},
                {id: 'tuesday', name: 'Terça-Feira'},
                {id: 'wednesday', name: 'Quarta-Feira'},
                {id: 'thursday', name: 'Quinta-Feira'},
                {id: 'friday', name: 'Sexta-Feira'},
                {id: 'saturday', name: 'Sábado'},
                {id: 'sunday', name: 'Domingo'},
              ],
            },
            closeDay: {
              input: 'select',
              label: 'Dia da Semana Fim',
              choices: [
                {id: 'monday', name: 'Segunda-Feira'},
                {id: 'tuesday', name: 'Terça-Feira'},
                {id: 'wednesday', name: 'Quarta-Feira'},
                {id: 'thursday', name: 'Quinta-Feira'},
                {id: 'friday', name: 'Sexta-Feira'},
                {id: 'saturday', name: 'Sábado'},
                {id: 'sunday', name: 'Domingo'},
              ],
            },
            openTime: {type: 'time', label: 'Hora Inicio'},
            closeTime: {type: 'time', label: 'Hora Fim'},
          },
        },
        'title.deliveryRegularHours': {
          input: 'title',
          label: 'Horário de funcionamento do delivery',
        },
        'deliveryRegularHours.status': {
          input: 'boolean',
          label: 'Exibir na página da loja?',
        },
        'deliveryRegularHours.useInShippingMethod': {
          input: 'boolean',
          label:
            'Utilizar no checkout para ativar/desativar o método de entrega conforme o horário?',
        },
        'deliveryRegularHours.periods': {
          input: 'array',
          label: 'Dia de funcionamento',
          fields: {
            openDay: {
              input: 'select',
              label: 'Dia da Semana Inicio',
              choices: [
                {id: 'monday', name: 'Segunda-Feira'},
                {id: 'tuesday', name: 'Terça-Feira'},
                {id: 'wednesday', name: 'Quarta-Feira'},
                {id: 'thursday', name: 'Quinta-Feira'},
                {id: 'friday', name: 'Sexta-Feira'},
                {id: 'saturday', name: 'Sábado'},
                {id: 'sunday', name: 'Domingo'},
              ],
            },
            closeDay: {
              input: 'select',
              label: 'Dia da Semana Fim',
              choices: [
                {id: 'monday', name: 'Segunda-Feira'},
                {id: 'tuesday', name: 'Terça-Feira'},
                {id: 'wednesday', name: 'Quarta-Feira'},
                {id: 'thursday', name: 'Quinta-Feira'},
                {id: 'friday', name: 'Sexta-Feira'},
                {id: 'saturday', name: 'Sábado'},
                {id: 'sunday', name: 'Domingo'},
              ],
            },
            openTime: {type: 'time', label: 'Hora Inicio'},
            closeTime: {type: 'time', label: 'Hora Fim'},
          },
        },
        createdAt: {
          input: 'datetime',
          label: 'Cadastrado em:',
          list: true,
          filter: false,
          disabled: true,
          props: {inline: true},
        },
        updatedAt: {
          input: 'datetime',
          label: 'Atualizado em:',
          list: true,
          filter: false,
          disabled: true,
          props: {inline: true},
        },
      },
      icon: props => <StoreIcon />,
    },
    {
      id: 'products',
      label: 'Produtos',
      view: false,
      edit: true,
      add: true,
      export: true,
      delete: true,
      import: true,
      fields: {
        // 'content.title.product': {
        //   input: 'title',
        //   label: 'Dados do Produto',
        //   list: false,
        // },
        sku: {
          input: 'text',
          label: 'SKU',
          list: true,
          filter: true,
          props: {inline: true},
        },
        name: {
          input: 'text',
          label: 'Nome',
          list: true,
          filter: true,
          props: {fullWidth: true},
        },
        shortDescription: {
          input: 'markdown',
          label: 'Descrição curta',
          list: false,
          filter: false,
          props: {fullWidth: true},
        },
        description: {
          input: 'markdown',
          label: 'Descrição',
          list: false,
          filter: false,
          props: {fullWidth: true},
        },
        // active: {input: 'boolean', label: 'Ativo?', list: true, filter: true},
        visible: {
          input: 'boolean',
          label: 'Visível para o consumidor?',
          list: true,
          filter: true,
          props: {inline: true},
        },
        stock: {
          input: 'number',
          label: 'Estoque atual do produto principal',
        },
        order: {
          input: 'number',
          label: 'Ordem',
        },
        stockManagementUnit: {
          input: 'select',
          label: 'Unidade de medida',
          list: true,
          filter: true,
          choices: [
            {
              id: '',
              name: '',
            },
            {
              id: 'unidade',
              name: 'Unidade(s)',
            },
            {
              id: 'vaga',
              name: 'Vaga(s)',
            },
            {
              id: 'litro',
              name: 'Litro(s)',
            },
            {
              id: 'peca',
              name: 'Peça(s)',
            },
          ],
        },
        stores: {
          input: 'array',
          label: 'Loja(s) a qual o produto pertence',
          fields: {
            store: {
              input: 'text',
              label: 'ID Loja',
            },
          },
        },
        type: {
          input: 'select',
          label: 'Tipo',
          list: true,
          filter: true,
          choices: [
            {
              id: '',
              name: '',
            },

            {
              id: 'product',
              name: 'Produto',
            },
            {
              id: 'promotion',
              name: 'Promoção',
            },
            {
              id: 'recharge',
              name: 'Recarga CASHBACK',
            },
            {
              id: 'service',
              name: 'Serviço',
            },
          ],
        },
        controlSalesPeriod: {
          input: 'boolean',
          label: 'Controla período de venda?',
          list: false,
          filter: false,
          props: {inline: true},
        },
        startSalesAt: {
          input: 'datetime',
          label: 'Começar a vender em:',
          list: true,
          filter: false,
          props: {inline: true},
        },
        endSalesAt: {
          input: 'datetime',
          label: 'Encerrar vendas em:',
          list: true,
          filter: false,
          props: {inline: true},
        },
        startPromotionAt: {
          input: 'datetime',
          label: 'Começar promoção em:',
          list: true,
          filter: false,
          props: {inline: true},
        },
        endPromotionAt: {
          input: 'datetime',
          label: 'Encerrar promoção em:',
          list: true,
          filter: false,
          props: {inline: true},
        },
        promotionMaxLuckyNumber: {
          input: 'number',
          label: 'Número da sorte máximos',
        },
        paymentMaxInstallments: {
          input: 'number',
          label: 'Número máximo de parcelas',
        },
        chargePaymentTax: {
          input: 'boolean',
          label: 'Cobrar a taxa de operação do comprador?',
          list: false,
          filter: false,
        },
        'sellTo.users': {
          input: 'boolean',
          label: 'Venda B2C?',
          list: false,
          filter: false,
        },
        'sellTo.stores': {
          input: 'boolean',
          label: 'Venda B2B?',
          list: false,
          filter: false,
        },

        cluster: {
          input: 'reference',
          referenceType: 'cluster',
          label: 'CDL',
          list: true,
        },
        hasShipping: {
          input: 'boolean',
          label: 'Tem entrega?',
          list: false,
          filter: false,
        },
        images: {input: 'images', label: 'Imagens'},
        price: {
          input: 'real',
          label: 'Preço',
          list: true,
          props: {inline: true},
        },
        specialPrice: {
          input: 'real',
          label: 'Preço Especial',
          list: true,
          props: {inline: true},
        },
        priceStores: {
          input: 'real',
          label: 'Preço B2B',
          list: true,
          props: {inline: true},
        },
        specialPriceStores: {
          input: 'real',
          label: 'Preço Especial B2B',
          list: true,
          props: {inline: true},
        },

        additional: {
          input: 'array',
          label: 'Produtos Adicionais',
          fields: {
            sku: {input: 'text', label: 'SKU'},
            description: {input: 'text', label: 'Descrição'},
            price: {input: 'number', label: 'Preço'},
            specialPrice: {input: 'number', label: 'Preço Especial'},
            priceStores: {input: 'number', label: 'Preço B2B'},
            specialPriceStores: {input: 'number', label: 'Preço Especial B2B'},
            minQty: {input: 'number', label: 'Quantidade Mínima de venda'},
            maxQty: {
              input: 'number',
              label: 'Quantidade Máxima de venda por pedido',
            },
            stock: {input: 'number', label: 'Estoque atual desse adicional'},
            stockManagementUnit: {
              input: 'select',
              label: 'Unidade de medida',
              list: true,
              filter: true,
              choices: [
                {
                  id: '',
                  name: '',
                },
                {
                  id: 'unidade',
                  name: 'Unidade(s)',
                },
                {
                  id: 'vaga',
                  name: 'Vaga(s)',
                },
                {
                  id: 'litro',
                  name: 'Litro(s)',
                },
                {
                  id: 'peca',
                  name: 'Peça(s)',
                },
              ],
            },
          },
        },
      },
    },
    {
      id: 'sales',
      label: 'Campanhas',
      view: true,
      edit: true,
      add: true,
      export: false,
      delete: true,
      import: false,
      icon: props => <LoyaltyIcon />,
      fields: {
        name: {
          input: 'text',
          label: 'Nome',
          filter: true,
          list: true,
        },
        couponName: {
          input: 'text',
          label: 'Nome do Cupom',
          filter: false,
          list: true,
        },
        active: {input: 'boolean', label: 'Ativo?', list: true, filter: true},
        cluster: {
          input: 'reference',
          referenceType: 'cluster',
          label: 'CDL',
          list: true,
          filter: true,
          props: {inline: true},
        },
        stores: {
          input: 'reference',
          referenceType: 'stores',
          label: 'Lojas',
          list: true,
          filter: true,
          multiplesChoices: true,
          props: {inline: true},
        },
        discount: {
          input: 'number',
          label: 'Desconto',
        },
        discountType: {
          label: 'Tipo do desconto',
          input: 'select',
          filter: false,
          list: false,
          choices: [
            {
              id: 'fixed',
              name: 'Fixo',
            },
            {
              id: 'percentage',
              name: 'Percentual',
            },
          ],
        },
      },
    },
    {
      id: 'transactions',
      label: 'Pedidos',
      view: true,
      edit: false,
      add: false,
      delete: false,
      fields: {
        'title.order': {input: 'title', label: 'Dados do Pedido'},
        createdAt: {
          input: 'datetime',
          label: <AccessAlarmIcon />,
          list: true,
          props: {inline: true},
        },
        id: {
          input: 'text',
          label: 'ID',
          list: false,
          filter: true,
          props: {inline: true},
        },
        process: {
          label: 'Processo',
          input: 'select',
          filter: true,
          list: true,
          choices: [
            {
              id: 'AppPaymentsTransaction',
              name: 'Pedido',
            },
            {
              id: 'AppChoppersClubTransaction',
              name: 'Recarga',
            },
            {
              id: 'LoyaltyCashbackTransaction',
              name: 'Cashback',
            },
            {
              id: 'LoyaltyProductsTransaction',
              name: 'Lojinha CDL',
            },
          ],
        },

        stores: {
          input: 'reference',
          referenceType: 'store',
          label: 'Loja',
          list: true,
          filter: true,
          props: {inline: true},
        },
        cluster: {
          input: 'reference',
          referenceType: 'cluster',
          label: 'CDL',
          list: true,
          filter: true,
          props: {inline: true},
        },
        status: {
          input: 'transactionsstatus',
          label: 'Status',
          list: true,
          filter: true,
          props: {inline: true},
          choices: [
            {
              id: 'Pagamento pendente',
              name: 'Pagamento pendente',
            },
            {
              id: 'Pagamento confirmado',
              name: 'Pagamento confirmado',
            },
            {
              id: 'Aguardando confirmação',
              name: 'Aguardando confirmação',
            },
            {
              id: 'Pedido confirmado',
              name: 'Pedido confirmado',
            },
            {
              id: 'Boleto gerado',
              name: 'Boleto gerado',
            },
            {
              id: 'Boleto enviado',
              name: 'Boleto enviado',
            },
            {
              id: 'Em preparação',
              name: 'Em preparação',
            },
            {
              id: 'Aguardando',
              name: 'Aguardando',
            },
            {
              id: 'Pronto para retirar',
              name: 'Pronto para retirar',
            },
            {
              id: 'Saiu para entrega',
              name: 'Saiu para entrega',
            },
            {
              id: 'Entrega efetuada',
              name: 'Entrega efetuada',
            },
            {
              id: 'Recarga efetuada',
              name: 'Recarga efetuada',
            },
            {
              id: 'Cancelado',
              name: 'Cancelado',
            },
          ],
        },
        paymentStatus: {
          input: 'select',
          label: 'Status do Pagamento',
          list: true,
          filter: true,
          props: {inline: true},
          choices: [
            {
              id: 'pending',
              name: 'Pendente',
            },
            {
              id: 'paid',
              name: 'Pago',
            },
            {
              id: 'error',
              name: 'Erro',
            },
          ],
        },
        accountId: {
          input: 'text',
          label: 'accountId',
          list: false,
        },
        comment: {input: 'text', label: 'Observação', list: false},
        'title.payment': {input: 'title', label: 'Pagamento'},
        'customer.paymentMethod.type': {
          input: 'select',
          label: 'Pagamento',
          list: true,
          filter: true,
          props: {inline: true},
          choices: [
            {
              id: 'debitCard',
              name: 'Cartão de Débito',
            },
            {
              id: 'creditCard',
              name: 'Cartão de Crédito',
            },
            {
              id: 'pix',
              name: 'PIX',
            },
            {
              id: 'faturaCdl',
              name: 'Fatura CDL',
            },
            {
              id: 'boletoManual',
              name: 'Boleto',
            },
            {
              id: 'boleto',
              name: 'Boleto',
            },
            {
              id: 'withdrawal',
              name: 'Na Entrega',
            },
          ],
        },

        'customer.paymentMethod.config.title': {
          input: 'text',
          label: 'Título',
          list: false,
          filter: true,
          props: {inline: true},
        },
        'customer.paymentMethod.config.description': {
          input: 'text',
          label: 'Descrição',
          list: false,
          filter: true,
          props: {inline: true},
        },
        'title.address': {input: 'title', label: 'Endereços'},
        'customer.billingAddress': {
          input: 'address',
          label: 'Endereço de Faturamento',
          list: true,
          filter: true,
          props: {inline: true},
        },
        'customer.shippingAddress': {
          input: 'address',
          label: 'Endereço de Entrega',
          list: true,
          filter: true,
          props: {inline: true},
        },
        'title.shipping': {input: 'title', label: 'Entrega'},
        'customer.shippingMethod.provider.config.title': {
          input: 'text',
          label: 'Entrega',
          list: false,
          filter: true,
          props: {inline: true},
        },
        'customer.shippingMethod.name': {
          input: 'text',
          label: 'Entrega',
          list: false,
          filter: true,
          props: {inline: true},
        },
        'title.products': {input: 'title', label: 'Produtos'},
        subtotal: {
          input: 'real',
          label: 'Subtotal',
          list: true,
          props: {inline: true},
        },
        tax: {
          input: 'real',
          label: 'Taxas',
          list: true,
          props: {inline: true},
        },
        total: {
          input: 'real',
          label: 'Total',
          list: true,
          props: {inline: true},
        },

        products: {
          input: 'products',
          label: 'Produtos',
          props: {fullWidth: true},
        },
      },
      icon: props => <ShoppingCartIcon />,
    },
  ];

  let product = {
    'product/cdl': [
      'stories',
      'stores',
      'help',
      'categories',
      'transactions',
      'products',
      'sales',
    ],
  };

  let productModules = [];

  let productModulesKey = {};
  productModules = productModules.concat(
    _.filter(modules, item => {
      productModulesKey[item.id] = item;
      return product[AppConfig.app.parentProduct].includes(item.id);
    }),
  );

  return (
    <Admin
      theme={Theme}
      layout={Layout}
      loginPage={Auth}
      logoutButton={AuthLogoutButton}
      authProvider={AuthProvider}
      dataProvider={DataProvider}
      // customRoutes={[
      //   <Route
      //     path="/transactions/:transaction_id/print"
      //     component={TransactionPrint}
      //     noLayout
      //   />,
      // ]}
      locale={'pt'}
      i18nProvider={i18nProvider}>
      <Resource
        options={{label: 'App'}}
        id={'App'}
        name={'App'}
        list={props => <Dashboard modules={productModules} {...props} />}
        icon={PhoneIphoneIcon}
      />
      {productModules.map(item => (
        <Resource
          options={{label: item.label}}
          id={item.id}
          name={item.id}
          list={props => <List config={item} fields={item.fields} {...props} />}
          match={false}
          icon={item.icon}
        />
      ))}
      {/* <Resource
        options={{label: 'CDLS'}}
        id={'stores'}
        name={'stores'}
        list={props => (
          <List
            config={productModulesKey['stores']}
            fields={productModulesKey['stores'].fields}
            {...props}
          />
        )}
        match={false}
        icon={productModulesKey['stores'].icon}
      />
      <Resource
        options={{label: 'Associados'}}
        id={'stores'}
        name={'stores'}
        list={props => (
          <List
            config={productModulesKey['stores']}
            fields={productModulesKey['stores'].fields}
            {...props}
          />
        )}
        match={false}
        icon={productModulesKey['stores'].icon}
      /> */}
    </Admin>
  );
};

export default App;
