import * as React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
// import {ListContextProvider, TextField} from 'react-admin';
// import keyBy from 'lodash/keyBy';
// import {DataGrid} from '@material-ui/data-grid';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
// import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
// import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const ProductsField = ({source, record = {}}) => {
  return (
    <div style={{width: '100%'}}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Produto</TableCell>
              {/* <TableCell align="right">Preço</TableCell> */}
              <TableCell align="right">Quantidade</TableCell>
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(record[source]).map((item, index) => (
              <TableRow key={item}>
                <TableCell component="th" scope="row">
                  {/* <Typography variant={'caption'}>{item}</Typography> */}

                  <Typography variant={'body2'} style={{fontWeight: 'bold'}}>
                    Produto #{index + 1}
                  </Typography>
                  <br></br>
                  <Typography variant={'body2'} style={{fontWeight: 'bold'}}>
                    {_.get(record[source][item], 'skuData.name')
                      ? _.get(record[source][item], 'skuData.name')
                      : _.get(record[source][item], 'name')}
                    - R$ {_.get(record[source][item], 'price')}
                    {/* {_.get(record[source][item], 'skuModelData')
                      ? _.get(record[source][item], 'skuData.name')
                      : _.get(record[source][item], 'skuData.name') +
                        ' - R$ ' +
                        _.get(record[source][item], 'skuData.price').toFixed(2)} */}
                  </Typography>

                  {_.get(record[source][item], 'additional') &&
                  Object.keys(_.get(record[source][item], 'additional'))
                    .length > 0 ? (
                    <>
                      <br></br>
                      <Typography variant={'caption'}>
                        ==== Adicionais ====
                      </Typography>
                      <div>
                        {Object.entries(
                          _.get(record[source][item], 'additional'),
                        ).map(([prop, value]) => (
                          <Typography variant={'body2'}>
                            <Typography variant={'caption'}>
                              {value.qty}x
                            </Typography>{' '}
                            -{' '}
                            <Typography
                              variant={'caption'}
                              style={{fontWeight: 'bold'}}>
                              {value.name ? value.name : value.sku}
                            </Typography>{' '}
                            -{' '}
                            <Typography variant={'caption'}>
                              R$ {value.price.toFixed(2)}
                            </Typography>
                          </Typography>
                        ))}
                      </div>
                    </>
                  ) : (
                    []
                  )}

                  {/* {_.get(record[source][item], 'options') && (
                    <>
                      <br></br>
                      <Typography variant={'caption'}>
                        ==== Opções Extras ====
                      </Typography>
                      <div>
                        <Typography
                          variant={'body2'}
                          style={{fontWeight: 'bold'}}>
                          {_.get(
                            record[source][item],
                            'skuData.optionsLabelName',
                          )}
                        </Typography>
                        {Object.entries(
                          _.get(record[source][item], 'options'),
                        ).map(([prop, value]) => (
                          <Typography variant={'body2'}>
                            {prop}:{' '}
                            <Typography
                              variant={'caption'}
                              style={{fontWeight: 'bold'}}>
                              {value}
                            </Typography>
                          </Typography>
                        ))}
                      </div>
                    </>
                  )} */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
ProductsField.defaultProps = {
  addLabel: false,
};
ProductsField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default ProductsField;
