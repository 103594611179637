import {createMuiTheme} from '@material-ui/core/styles';
import ThemeConfig from '../../../ThemeLandingPage.json';
import red from '@material-ui/core/colors/red';

const Theme = createMuiTheme({
  palette: {
    background: {
      main: '#000',
    },
    canvasColor: '#ffeb3b',
    primary: {
      main: ThemeConfig.backgroundColor,
    },
    secondary: {
      main: ThemeConfig.contentTextColor,
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  overrides: {
    MuiButton: {
      // override the styles of all instances of this component
      root: {
        // Name of the rule
        // color: 'white', // Some CSS
      },
    },
  },
});
export default Theme;
