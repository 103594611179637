import React, {cloneElement, useMemo, Fragment} from 'react';
import _ from 'lodash';
import {
  useShowController,
  TopToolbar,
  DeleteWithConfirmButton,
  EditButton,
  CloneButton,
} from 'react-admin';

const ViewActions = (props) => {
  const {resource, record} = useShowController(props);
  if (!props.config.add && !props.config.edit && !props.config.delete) {
    return null;
  }
  return (
    <TopToolbar
      style={{
        // position: 'fixed',
        // bottom: 0,
        // right: 0,
        // left: 0,
        // backgroundColor: '#eee',
        // zIndex: 1,
        // paddingTop: 15,
        // paddingBottom: 15,
        // paddingLeft: 15,
        // paddingRight: 15,
        justifyContent: 'space-between',
      }}>
      <div>
        {props.config.delete && props.data?.id && (
          <DeleteWithConfirmButton
            resource={resource}
            record={record}
            id={props.data.id}
            confirmTitle={'Confirma Apagar?'}
            confirmContent={'Atenção! Essa ação não será ser revertida.'}
            {...props}
          />
        )}
      </div>
      <div>
        {props.config.add && (
          <CloneButton basePath={`${props.basePath}`} record={props.data} />
        )}
        {props.config.edit && (
          <EditButton basePath={`${props.basePath}`} record={props.data} />
        )}
      </div>
    </TopToolbar>
  );
};

export default ViewActions;
