// in src/MyLoginPage.js
import * as React from 'react';
import {useState, forwardRef} from 'react';
import {
  useLogin,
  useNotify,
  Notification,
  // defaultTheme,
  useLogout,
} from 'react-admin';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';

import MenuItem from '@material-ui/core/MenuItem';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';
import LogoImg from '../../../logo.svg';
import ThemeConfig from '../../../ThemeLandingPage.json';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    backgroundColor: ThemeConfig.backgroundColor,
    margin: theme.spacing(3, 0, 2),
  },
}));

export const Auth = ({theme}) => {
  const [username, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const login = useLogin();
  const notify = useNotify();
  const submit = e => {
    e.preventDefault();
    console.log(username, password);
    login({username, password}).catch(e => {
      console.log('eerroro', e);
      notify('Invalid username or password');
    });
  };
  const classes = useStyles();

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <CssBaseline />
      <div style={{width: 300}}>
        <div
          style={{
            width: '100%',
          }}>
          <img
            alt=""
            src={LogoImg}
            style={{
              width: 100,
              height: 100,
              marginBottom: 20,
              borderRadius: 10,
            }}
          />
          <Typography variant="h4">{process.env.REACT_APP_TITLE}</Typography>
          <Typography variant="h6">{ThemeConfig.headerText}</Typography>
        </div>
        <form className={classes.form} noValidate onSubmit={submit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Email"
            name="username"
            autoComplete="username"
            autoFocus
            onChange={e => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={e => setPassword(e.target.value)}
          />
          <Button
            size={'large'}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={submit}>
            Entrar
          </Button>
          <Notification />
        </form>
      </div>
    </div>
  );
};

export const AuthLogoutButton = forwardRef((props, ref) => {
  const logout = useLogout();
  const handleClick = () => logout();
  return (
    <MenuItem onClick={handleClick} ref={ref}>
      <ExitIcon /> Logout
    </MenuItem>
  );
});
