import * as React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';

const AddressField = ({source, record = {}}) => {
  return (
    <Typography variant={'body2'}>
      {_.get(record, `${source}.name`)}
      <br />
      {_.get(record, `${source}.street`)} {_.get(record, `${source}.number`)}
      {` `}
      {_.get(record, `${source}.complement`)}
      <br />
      {_.get(record, `${source}.street2`)}
      <br />
      {_.get(record, `${source}.city`)} {_.get(record, `${source}.state`)}
      <br />
      {_.get(record, `${source}.zipcode`)}
      <br />
    </Typography>
  );
};
AddressField.defaultProps = {
  addLabel: true,
};
AddressField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default AddressField;
