import React, {useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {ChipField, ReferenceField, useDataProvider} from 'react-admin';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

const TransactionsStatusField = (props) => {
  const dataProvider = useDataProvider();
  const [status, setStatus] = React.useState(
    props.record.status.charAt(0).toUpperCase() +
      props.record.status.slice(1).toLowerCase(),
  );
  const handleChange = (event) => {
    setStatus(event.target.value);
    if (
      props.record.account &&
      props.record.account.type &&
      props.record.account.name &&
      props.record.account.id
    ) {
      dataProvider.update('transactions/status', {
        id: props.record.id.split('-')[3],
        data: {
          transactionId: props.record.id.split('-')[3],
          status: event.target.value,
          accountId: props.record.account.id,
          accountType: props.record.account.type,
        },
      });
    }
    if (props.record.accountType && props.record.accountId) {
      dataProvider.update('transactions/status', {
        id: props.record.id.split('-')[3],
        data: {
          transactionId: props.record.id.split('-')[3],
          status: event.target.value,
          accountId: props.record.accountId,
          accountType: props.record.accountType,
        },
      });
    }
  };
  return (
    <FormControl>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={status}
        onChange={handleChange}>
        {props.choices.map((item) => (
          <MenuItem value={item.id}>{item.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
  if (
    props.record.account &&
    props.record.account.type &&
    props.record.account.name &&
    props.record.account.id
  ) {
    console.log('props', props);
    return (
      <ReferenceField
        label={props.label}
        source="account.id"
        reference={props.record.account.type}
        {...props}>
        <ChipField
          size="small"
          source={props.record.account.type === 'users' ? 'firstName' : 'name'}
        />
      </ReferenceField>
    );
  }
  if (props.record.accountType && props.record.accountId) {
    console.log('props', props);
    return (
      <ReferenceField
        label={props.label}
        source="accountId"
        reference={props.record.accountType}
        {...props}>
        <ChipField size="small" source="firstName" />
      </ReferenceField>
    );
  }
  return null;
};
TransactionsStatusField.defaultProps = {
  addLabel: true,
};
TransactionsStatusField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default TransactionsStatusField;
