import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn.css';
// import 'bpmn-js-properties-panel/dist/assets/bpmn-js-properties-panel.css';
import './dist/styles.css';
import './dist/bpmn-js-properties-panel.css';

import App from './modules/App/index';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
