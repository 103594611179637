import * as React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import Bpmn from '../components/Bpmn';

const BpmnField = ({source, record = {}}) => {
  return (
    <Bpmn viewerOnly={true} value={record} style={{backgroundColor: '#CCC'}} />
  );
};
BpmnField.defaultProps = {
  addLabel: false,
};
BpmnField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default BpmnField;
