import * as React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  SelectInput,
  ReferenceInput,
  ReferenceArrayInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  required,
} from 'react-admin';

const ReferenceCustomInput = props => {
  //   if (props.referenceType === 'stores') {
  //     ///para contas de usuarios/empresas mas em outro local as infos...
  //     if (
  //       props.record.account &&
  //       props.record.account.type &&
  //       props.record.account.name &&
  //       props.record.account.id
  //     ) {
  //       return (
  //         <ReferenceField
  //           label={props.label}
  //           source="account.id"
  //           reference={props.record.account.type}
  //           {...props}>
  //           <ChipField
  //             size="small"
  //             source={
  //               props.record.account.type === 'users' ? 'firstName' : 'name'
  //             }
  //           />
  //         </ReferenceField>
  //       );
  //     }
  ///para contas de usuarios/empresas
  //     if (props.record.accountType && props.record.accountId) {
  //       return (
  //         <ReferenceField
  //           label={props.label}
  //           source="accountId"
  //           reference={props.record.accountType}
  //           {...props}>
  //           <ChipField size="small" source="firstName" />
  //         </ReferenceField>
  //       );
  //     }
  //   }
  //exibir cluster das stories
  //   if (props.referenceType === 'cluster') {
  //     if (props.record.cluster && props.record.cluster !== '') {
  //       return (
  //         <ReferenceField
  //           label={props.label}
  //           source="cluster"
  //           reference={'stores'}
  //           {...props}>
  //           <ChipField size="small" source="name" />
  //         </ReferenceField>
  //       );
  //     }
  //   }

  ///exibir parentId do help

  ///se for input quer dizer que é um adição ou edição do formulário, então exibe outro tipo de input
  if (props.referenceType === 'cluster') {
    if (props.multiplesChoices) {
      return (
        <ReferenceArrayInput
          label={props.label}
          source={props.source}
          reference="stores"
          allowEmpty={true}
          perPage={5}
          filter={{type: props.referenceType}}
          sort={{field: 'name', order: 'ASC'}}>
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
      );
    } else {
      return (
        <ReferenceInput
          label={props.label}
          source={props.source}
          reference="stores"
          allowEmpty={true}
          perPage={5}
          filter={{type: props.referenceType}}
          sort={{field: 'name', order: 'ASC'}}>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      );
    }
  }

  if (props.referenceType === 'stores') {
    if (props.multiplesChoices) {
      return (
        <ReferenceArrayInput
          label={props.label}
          source={props.source}
          reference="stores"
          allowEmpty={true}
          perPage={5}
          sort={{field: 'name', order: 'ASC'}}>
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
      );
    } else {
      return (
        <ReferenceInput
          label={props.label}
          source={props.source}
          reference="stores"
          allowEmpty={true}
          perPage={5}
          sort={{field: 'name', order: 'ASC'}}>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      );
    }
  }

  if (props.referenceType === 'help') {
    console.log('help', props);
    return (
      <ReferenceInput
        label={props.label}
        source={props.source}
        reference="help"
        allowEmpty={true}
        perPage={5}
        sort={{field: 'title', order: 'ASC'}}>
        <AutocompleteInput
          optionText="title"
          emptyText={'== Sem Ajuda Pai =='}
          optionText={props.optionText}
        />
      </ReferenceInput>
    );
  }

  // if (props.referenceType === 'marketing') {
  //   return (
  //     <ReferenceInput
  //       label={props.label}
  //       source={props.source}
  //       reference="marketing"
  //       allowEmpty={true}
  //       perPage={5}
  //       sort={{field: 'title', order: 'ASC'}}>
  //       <AutocompleteInput optionText="title" />
  //     </ReferenceInput>
  //   );
  // }

  if (props.referenceType === 'categories') {
    console.log(props);
    if (props.multiplesChoices) {
      return (
        <ReferenceArrayInput
          label={props.label}
          source={props.source}
          reference="categories"
          perPage={5}
          sort={{field: 'name', order: 'ASC'}}>
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
      );
    } else {
      return (
        <ReferenceInput
          label={props.label}
          source={props.source}
          reference="categories"
          perPage={5}
          sort={{field: 'name', order: 'ASC'}}>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      );
    }
  }

  ///exibir parentId do categories
  //   if (props.referenceType === 'categories') {
  //     if (props.record.categoryId && props.record.categoryId !== '') {
  //       return (
  //         <ReferenceField
  //           label={props.label}
  //           source="categoryId"
  //           reference={'categories'}
  //           {...props}>
  //           <ChipField size="small" source="name" />
  //         </ReferenceField>
  //       );
  //     }
  //   }
  return null;
};
ReferenceCustomInput.defaultProps = {
  addLabel: true,
};
ReferenceCustomInput.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default ReferenceCustomInput;
