import * as React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import Bpmn from '../components/Bpmn';
import {useInput, required} from 'react-admin';

const BpmnInput = (props) => {
  const {
    input: {name, onChange, ...rest},
    meta: {touched, error},
    isRequired,
  } = useInput(props);
  console.log('value', props);
  return (
    <div style={{width: '100%', height: '100vh', backgroundColor: '#CCC'}}>
      <Bpmn
        name={name}
        value={props.record}
        onChange={onChange}
        style={{height: '100vh', backgroundColor: '#CCC'}}
      />
    </div>
  );
};

export default BpmnInput;
